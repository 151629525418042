import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const STORES = [
  "<b>ESPAÇO PORTINARI E CEUSA</b> – RUA CUNHA GAGO, 106 – PINHEIROS – SÃO PAULO-SP (11) 3034-0800",
  "<b>PINHEIROS</b> – RUA DOS PINHEIROS, 1278/1282 – PINHEIROS – SÃO PAULO-SP (11) 3031-6891.",
  "<b>ZONA NORTE</b> – AV ENG. CAETANO ALVARES, 4601/4409 – IMIRIM – SÃO PAULO-SP (11) 2236-2799.",
  "<b>ITAIM (DECA, PORTINARI, CEUSA)</b> – RUA DR ALCEU DE CAMPOS RODRIGUES 410 – ITAIM – SÃO PAULO – SP (11) 3045-1095.",
  "<b>ALPHAVILLE</b> – AL ARAGUAIA, 891 – BARUERI – SÃO PAULO-SP (11) 4191-1508.",
  "<b>ANÁLIA FRANCO</b> – RUA ELEONORA CINTRA, 87 – JD ANÁLIA FRANCO – SÃO PAULO-SP (11) 2268-0126",
  "<b>SÃO JOSÉ DOS CAMPOS</b> – AV SÃO JOÃO, 85 – JD ESPLANADA – SÃO JOSÉ DOS CAMPOS – SP (12) 3922-0920",
  "<b>CAMPINAS</b> – AVENIDA JULIO DE MESQUITA, 839 – CAMBUÍ – CAMPINAS – SP (19) 3325-1006",
]

export default function Footer() {
  return (
    <>
      <div className="bg-dark p-b-10 m-t-10">
        <div className="footer">
          <div className="container position-relative">
            <div className="row d-block d-lg-flex align-items-center">
              <div className="cols">
                <div className="logo">
                  <a href="/">
                    <img src="lib/assets/img/logo-footer.png" alt="logo-footer" />
                  </a>
                </div>
              </div>
              <div className="cols">
                <div className="separa"></div>
              </div>
              <div className="cols">
                <div className="text" style={{ fontSize: "16px" }}>
                  {STORES ? STORES.map((i, index) => <div key={index} >{ReactHtmlParser(i)}</div>) : <></>}
                </div>
              </div>
            </div>
          </div>
          <div className="container" style={{ padding: "0px 40px 40px 40px" }}>
            <div className="row align-items-center justify-content-between">
              <div className="cols">
                <div className="logo">
                  <span>
                    <img src="https://hubcoapp-images.s3-sa-east-1.amazonaws.com/logos/logo-deca.png" alt="logo-deca" style={{ maxWidth: "80%" }} />
                  </span>
                </div>
              </div>
              <div className="cols">
                <div className="logo">
                  <span>
                    <img src="https://hubcoapp-images.s3-sa-east-1.amazonaws.com/logos/logo-hydra.png" alt="logo-hydra" style={{ maxWidth: "80%" }} />
                  </span>
                </div>
              </div>
              <div className="cols">
                <div className="logo">
                  <span>
                    <img src="https://hubcoapp-images.s3-sa-east-1.amazonaws.com/logos/logo-durafloor.png" alt="logo-duraflour" style={{ maxWidth: "80%" }} />
                  </span>
                </div>
              </div>
              <div className="cols">
                <div className="logo">
                  <span>
                    <img src="https://hubcoapp-images.s3-sa-east-1.amazonaws.com/logos/logo-portinari.png" alt="logo-portinari" style={{ maxWidth: "80%" }} />
                  </span>
                </div>
              </div>
              <div className="cols">
                <div className="logo">
                  <span>
                    <img src="https://hubcoapp-images.s3-sa-east-1.amazonaws.com/logos/tramontina.png" alt="tramontina" style={{ maxWidth: "80%" }} />
                  </span>
                </div>
              </div>
              <div className="cols">
                <div className="logo">
                  <span>
                    <img src="https://hubcoapp-images.s3-sa-east-1.amazonaws.com/logos/sanitrit.png" alt="sanitrit" style={{ maxWidth: "80%" }} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}
