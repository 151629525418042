import React from 'react'
import { ApiLocal } from '../constants'
import Swal from 'sweetalert2'
import { ClipLoader } from 'react-spinners'
import { Styles } from '../helpers'
import './style.css'


export default function ResetPassword(props) {
  const [state, setState] = React.useState({ password: "", password_confirmation: "", loading: false })

  React.useEffect(() => {
    let query = props.location.search.split("&c="),
      fedRegis = atob(query[1]),
      token = query[0].substr(3)
    if (query.length === 2) {
      ApiLocal("GET", `validate_to_reset_password/${fedRegis}?reset_password_token=${token}`)
        .then(_ => {
          setState({
            federal_registration: fedRegis,
            reset_password_token: token,
            loading: false
          })
        })
        .catch(
          _ => {
            Swal.fire({
              type: "error",
              title: "Oops...",
              text: "Não foi possivel Continuar o 'Esqueceu sua senha?'",
              timer: 2000,
              showConfirmButton: false,
            })
            props.history.push("/")
          }
        )
    }
    else {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Não foi possivel Continuar o 'Esqueceu sua senha?'",
        timer: 2000,
        showConfirmButton: false,
      })
      props.history.push("/")
    }
  }, [])

  const submitPassword = e => {
    e.preventDefault()
    setState({ loading: true })
    if (validBody())
      ApiLocal("POST", "reset_password", {
        federal_registration: state.federal_registration,
        reset_password_token: state.reset_password_token,
        password: state.password,
        password_confirmation: state.password_confirmation
      }).then((response) => {
        Swal.fire({
          type: "success",
          title: response.data.success,
          text: "Agora voce pode fazer login normalmente",
          timer: 2000,
          showConfirmButton: false,
        }).then(_ => props.history.push("/"))
      })
    else
      setState({ loading: false })

  }

  const validBody = () => {
    if (!state.password || state.password.length < 6) {
      Swal.fire('Oops...', 'Nova senha deve conter no minimo 6 caracteres', 'error')
      return false
    } else if (!state.password_confirmation || state.password_confirmation !== state.password) {
      Swal.fire('Oops...', `Confirmação de senha não coincide ${state.password_confirmation} - ${state.password}`, 'error')
      return false
    }
    return true
  }
  const handleChange = (key) => (event) => setState({ ...state, [key]: event.target.value })

  return (
    <form className="row justify-content-center p-t-100" onSubmit={submitPassword}>
      <div className="form-group text-center">
        <span style={Styles.font} className="signIn10000-form-title p-b-30 p-t-0 ">Redefinir senha</span>
        <div className="row justify-content-center">
          <label style={Styles.font} className="signin-label" htmlFor="pass">Senha</label>
          <div className="wrap-signIn10000 m-b-25" data-validate="Senha">
            <input disabled={state.loading} className="input10000" type="password" name="pass" value={state.password} onChange={handleChange("password")} />
          </div>
        </div>
        <div className="row justify-content-center">
          <label style={Styles.font} className="signin-label" htmlFor="passConfirmation">Confirmação de Senha</label>
          <div className="wrap-signIn10000 m-b-25" data-validate="Senha">
            <input disabled={state.loading} className="input10000" type="password" name="passConfirmation" value={state.password_confirmation} onChange={handleChange("password_confirmation")} />
          </div>
        </div>

        <div className="container-signIn10000-form-btn">
          <button disabled={state.loading} className="signIn100000-form-btn">
            {!state.loading ? <span className="" style={Styles.font} >Enviar nova senha</span> : <ClipLoader
              sizeUnit={"px"}
              size={30}
              color={'white'}
              loading={state.loading}
            />}</button>
        </div>
      </div>
    </form>
  )
}
