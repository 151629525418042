import React from 'react';
import { signOut } from '../constants';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as $ from 'jquery';
const defaultList = [
  {
    name: "PROFISSIONAIS",
    path: "/profissionais",
  },
  {
    name: "EVENTOS",
    path: "/eventos",
  },
  {
    name: "CAMPANHAS",
    path: "/campanhas",
  },
  {
    name: "INDIQUE UM ARQUITETO",
    path: "/indique",
  },
  {
    name: "Projetos",
    path: "/projetos",
  },
  {
    name: "FAQ",
    path: "/faq",
  },
  {
    name: "Contato",
    path: "/contato",
  },
]
export default function NavBar() {
  const partner = useSelector(state => state.partner);
  const [searcher, setSearcher] = React.useState("")
  const [list, setList] = React.useState(defaultList)
  const menuLiStyle = { paddingBottom: "7px", paddingTop: "7px", display: "block", paddingLeft: "10px", fontWeight: "bold", color: "#000", background: "#ffd65c", borderBottom: "1px solid #f6be18", cursor: "pointer" }

  const navLinks = (e) =>
    <ul className="">
      <li><Link style={{ cursor: "pointer", fontFamily: 'proxima_novaregular' }} className={window.location.pathname === "/" ? "active" : ""} to="/">HOME</Link></li>
      <li><Link style={{ cursor: "pointer", fontFamily: 'proxima_novaregular' }} to="/cadastrar" className={window.location.pathname === "cadastrar" ? "active" : ""}>CADASTRAR</Link></li>
      <li><Link style={{ cursor: "pointer", fontFamily: 'proxima_novaregular' }} to="/profissionais" className={window.location.pathname === "profissionais" ? "active" : ""}>PROFISSIONAIS</Link></li>
      <li><Link style={{ cursor: "pointer", fontFamily: 'proxima_novaregular' }} to="/projetos" className={window.location.pathname === "projetos" ? "active" : ""}>PROJETOS</Link></li>
      <li><Link style={{ cursor: "pointer", fontFamily: 'proxima_novaregular' }} to="/eventos" className={window.location.pathname === "eventos" ? "active" : ""}>EVENTOS</Link></li>
      {/* <li><Link style={{ cursor: "pointer", fontFamily: 'proxima_novaregular' }} to="/campanhas" className={window.location.pathname === "campanhas" ? "active" : ""}>CAMPANHAS</Link></li> */}
      <li><Link style={{ cursor: "pointer", fontFamily: 'proxima_novaregular' }} to="/indique" className={window.location.pathname === "indique" ? "active" : ""}>INDIQUE UM ARQUITETO</Link></li>
      <li><Link style={{ cursor: "pointer", fontFamily: 'proxima_novaregular' }} to="/faq" className={window.location.pathname === "faq" ? "active" : ""}>FAQ</Link></li>
      <li><Link style={{ cursor: "pointer", fontFamily: 'proxima_novaregular' }} to="/contato" className={window.location.pathname === "contato" ? "active" : ""}>CONTATO</Link></li>
    </ul>

  const listing = list.map((item) => {
    if (searcher)
      return <li key={item.path}><Link to={item.path}>{item.name}</Link></li>;
    return;
  });

  React.useEffect(() => {
    setSearcher("")
  }, [window.location.pathname]);

  React.useEffect(() => {
    if (searcher.length) {
      let l = list;
      l = l.filter(function (item) {
        return item.name.toLowerCase().indexOf(searcher) !== -1;
      });
      setList(l);
    }
    else
      setList(defaultList)
  }, [searcher]);

  const navMobile = (e) => {
    e.preventDefault()
    let larguraMnResponsivo = $('.areaMenuResponsivo').width();
    if ($('.areaMenuResponsivo').css('visibility') === 'hidden') {
      $('.overlay').show().addClass('atv');
      $('body').css('overflow', 'hidden');
      $('.areaMenuResponsivo').css('visibility', 'visible');
      $(".areaMenuResponsivo").animate({ right: "0" }, 300, function () {
      });
    }
    else {
      $(".areaMenuResponsivo").animate({ right: "-" + larguraMnResponsivo }, 300, function () {
        $('body').css('overflow', 'auto');
        $('.areaMenuResponsivo').css('visibility', 'hidden');
        $('.overlay').hide().removeClass('atv');
      });
    }
  }

  return (
    <>
      <div className="header">
        <div className="container">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="cols col-L">
              <div className="logo">
                <Link to="/"><img src="lib/assets/img/logo-header.png" alt="Obra fácil - Deca - Tramontina" /></Link>
              </div>
            </div>
            {
              localStorage.getItem('access-token') ?
                <div className="cols col-R">
                  <div className="top-desktop">
                    <div className="d-flex menu-int justify-content-between  align-items-center">
                      <Link to="/">Home</Link> <span>|</span>
                      <Link to="/">Ajuda</Link> <span>|</span>
                      {window.location.pathname === "/dashboard" ? <Link to="/me">Meus dados</Link> : <Link to="/dashboard">Dashboard</Link>}
                      <span>|</span>
                      <Link to="/" style={{ cursor: "pointer" }} onClick={signOut}>Sair</Link>
                      <div className="ico-photo-user">
                        <img src={partner.avatar.rounded_small ? partner.avatar.rounded_small : "lib/assets/img/ico-photo-user.png"} alt="" />
                      </div>

                    </div>
                  </div>
                </div>
                :
                <div className="cols col-R">
                  <div className="top-desktop">
                    <div className="d-flex justify-content-between  align-items-center">
                      <div className="h-busca">
                        <div className="position-relative">
                          <input type="text" name="busca" className="cp-busca" autoComplete="off"
                            placeholder="Digite o que está procurando" onChange={e => setSearcher(e.target.value)} value={searcher} />
                          <ul>
                            {listing}
                          </ul>
                          <button className="bt-busca" onClick={e => e.preventDefault()}></button>
                        </div>
                      </div>
                      <div className="h-social">
                        <a href="https://www.facebook.com/Obrafacil/" rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook-f"></i></a>
                        <a href="https://www.instagram.com/lojaobrafacil/" rel="noopener noreferrer" target="_blank"><i className="fab fa-instagram"></i></a>
                        <a href="https://lojaobrafacil.com.br/" rel="noopener noreferrer" target="_blank"><i className="fab fa-chrome"></i></a>
                        {/* <a href="" rel="noopener noreferrer" target="_blank"><i className="fab fa-whatsapp"></i></a> */}
                      </div>
                    </div>
                  </div>
                </div>}
            <div className="top-mobile p-r-10">
              <span style={{ cursor: "pointer" }} className="bt-resp" onClick={navMobile}><i className="fas fa-bars"></i></span>
            </div>
          </div>
        </div>
      </div>
      <div className="areaMenuResponsivo">
        <div className="overflow">
          <div className="menuResponsivo">
            <div className="btMenuFechar efeito-01"><i className="fa fa-arrow-right "></i></div>
            {localStorage.getItem('access-token') ? <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/">Ajuda</Link></li>
              <li>{
                window.location.pathname === "/dashboard" ?
                  <Link className={window.location.pathname === "dashboard" ? "active" : ""} to="/" data-toggle="modal" data-target="#modal-meus-dados">Meus dados</Link>
                  :
                  <Link className={window.location.pathname === "dashboard" ? "active" : ""} to="/dashboard">Meus dados</Link>
              }</li>
              <li><span style={menuLiStyle} onClick={signOut}>Sair</span></li>
            </ul>
              :
              navLinks()
            }
          </div>
        </div>
      </div>


      {window.location.pathname === "/dashboard" ?
        <></>
        :
        <div className="header-menu">
          <div className="container">
            <div className="d-none d-xl-block d-lg-block">
              <div className="d-inline-block menu">
                {navLinks()}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
