import React from 'react';
import { ApiLocal } from '../../constants'
import ReactHtmlParser from 'react-html-parser';
import {
  CardMedia,
  Typography,
  Hidden
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    height: "400px",
    maxWidth: "600px",
    maxHeight: "600px",
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));


export default function Highlights(props) {
  const classes = useStyles();
  const [highlights, setHighlights] = React.useState([]);
  const [perPage] = React.useState(9)
  const [page, setPage] = React.useState(1)
  const [total, setTotal] = React.useState(0)

  React.useEffect(() => {
    ApiLocal("GET", `highlights?per_page=${perPage}&page=${page}`)
      .then(response => { setHighlights([...highlights, ...response.data]); setTotal(response.headers.total) })
  }, [page])

  return (<>
    <div className="top-interna img-wide" style={{ backgroundImage: "url(lib/assets/img/content/top-eventos.jpg)" }}>
      <div className="container position-relative">
        <div className="row h-100 d-flex align-items-center">
          <div className="col-md-3">
            <div className="campanha-logo">
              <img src="lib/assets/img/logo-campanha.png" alt='logo-campanha' />
            </div>
          </div>
          <div className="col-md-9">
            <div className="page-title">
              <h1>Destaques</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="page-content">
      <div className="container pb-0 js-container">
      </div>
    </div>
    <div className="container">
      {highlights.length !== 0 ?
        <>
          {highlights.map((item, index) => {
            return <div className="row p-t-10" key={index}>
              <div className="col-md-6 col-sm-12">
                <Typography component="h5" variant="h5">
                  {item.title}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {(() => {
                    let today = new Date();
                    let a = item.starts_in ? new Date(item.starts_in) : null
                    let b = item.expires_at ? new Date(item.expires_at) : null
                    if (a && today.getDay() === a.getDay() && today.getMonth() === a.getMonth() && today.getFullYear() === a.getFullYear())
                      if (today.getHours() >= a.getHours() && today.getMinutes() >= a.getMinutes())
                        return `Começou as ${a.getHours()}:${a.getMinutes()} ${b ? `e termina as ${b.getHours()}:${b.getMinutes()}` : ''}`;
                      else
                        return `Começa hoje as ${a.getHours()}:${a.getMinutes()} ${b ? `e termina as ${b.getHours()}:${b.getMinutes()}` : ''}`;
                    return a ? b ? a.toLocaleString() + ' - ' + b.toLocaleString() : a.toLocaleString() : "";
                  })()}
                </Typography>
                <Hidden smDown implementation="css">
                  <Typography className={classes.cardBody} variant="body2" color="textSecondary" component="p">{ReactHtmlParser(item.metadata)}</Typography>
                </Hidden>
                <div>
                  <a style={{ fontSize: "15" }} href={`/destaques/${item.id}`}>ver <strong>mais</strong></a>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <CardMedia
                  className={classes.cover}
                  image={item.image.url}
                  title="Live from space album cover"
                />
              </div>
              <div className="container">
                <Hidden smUp implementation="css"> <Typography variant="subtitle1" color="textSecondary">{item.subtitle}</Typography></Hidden>
                <Hidden smUp implementation="css">
                  <Typography className={classes.cardBody} variant="body2" color="textSecondary" component="p">{ReactHtmlParser(item.metadata)}</Typography>
                </Hidden>
              </div>
            </div>
          })}
          {total > 0 && highlights.length > total ?
            <div className="row justify-content-end">
              <button className="btn btn-dark" onClick={e => setPage(page + 1)}>Carregar mais</button>
            </div> : <></>}
        </>
        :
        <div className="container">
          <div className="row justify-content-center p-t-100 p-b-100">
            <div className="col-md-4">
              <img src="https://hubcoapp-images.s3-sa-east-1.amazonaws.com/logos/obrafacil-logo.png" alt="loading" className="card-img-top op-0-6" />
            </div>
          </div>
        </div>
      }
    </div>
  </>
  )
}
