import React from 'react';
import FooterHighlights from './footer/highlights';

export default function Faq() {

  return (
    <>
      <div className="top-interna img-wide" style={{ backgroundImage: "url(lib/assets/img/content/top-faq.jpg)" }}>
        <div className="container position-relative">
          <div className="row h-100 d-flex align-items-center">
            <div className="col-md-3">
              <div className="campanha-logo">
                <img src="lib/assets/img/logo-campanha.png" alt='logo-campanha' /></div>
            </div>
            <div className="col-md-9">
              <div className="page-title">
                <h1>Perguntas e <strong>respostas</strong></h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container js-container position-relative">

          <p>&nbsp;</p>

          <div id="accordion" className="accordion">
            <div className="card">
              <div className="card-header" id="headingOne">
                <h5 className="mb-0">
                  <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">O que é o programa Obra Fácil Mais?</button>
                </h5>
              </div>
              <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                <div className="card-body">Obra Fácil Mais é o programa de relacionamento da Loja Obra Fácil que gera pontuações para resgate de prêmios.</div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingTwo">
                <h5 className="mb-0">
                  <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Quem pode participar?</button>
                </h5>
              </div>
              <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <div className="card-body">O programa é destinado a arquitetos, decoradores e design de interiores.</div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingThree">
                <h5 className="mb-0">
                  <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Como faço para me cadastrar?</button>
                </h5>
              </div>
              <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                <div className="card-body">Pode se cadastrar através deste site clicando em “cadastrar” ou " faça seu cadastro" ou através do site da Loja Obra Fácil, clicando no ícone do programa dentro do site.</div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingFour">
                <h5 className="mb-0">
                  <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Quais as vantagens para o profissional cadastrado?</button>
                </h5>
              </div>
              <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                <div className="card-body">
                  <ul>
                    <li>- vai resgatar seus pontos em dinheiro ou vale compras da Loja Obra Fácil.</li>
                    <li>- recebe pontuações bônus para resgate em outras lojas parceiras do site.</li>
                    <li>- participa da campanha de ranking mensal e anual de prêmios e viagens.</li>
                    <li>- recebe a oportunidade de divulgar seu trabalho nas plataformas virtuais da Loja Obra Fácil e outros veículos.</li>
                    <li>- as compras vinculadas ao seu cadastro serão cadastradas também na plataforma do Deca Exclusive.(programa de relacionamento da Deca)</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingFive">
                <h5 className="mb-0">
                  <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Qual a vantagem do programa aos meus clientes?</button>
                </h5>
              </div>
              <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                <div className="card-body">Através do sue cadastro, seus clientes recebem vantagens no fechamento das compras, seja em descontos especiais ou maior flexibilidade em parcelamentos.</div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingSix">
                <h5 className="mb-0">
                  <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">Quando eu recebo meus pontos?</button>
                </h5>
              </div>
              <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                <div className="card-body">Os pontos gerados referente a compras efetuadas até o dia último dia de cada mes, são disponibilizados no site para resgate em até 10 dias úteis do mês seguinte.</div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingSeven">
                <h5 className="mb-0">
                  <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">Como faço para acessar minha conta no programa?</button>
                </h5>
              </div>
              <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                <div className="card-body">O acesso é feito através do site www.obrafacilmais.com.br e você recebe sua senha de acesso no e-mail cadastrado assim que sua primeira pontuação for liberada para resgate.</div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingEigth">
                <h5 className="mb-0">
                  <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEigth" aria-expanded="false" aria-controls="collapseEigth">Meus pontos expiram?</button>
                </h5>
              </div>
              <div id="collapseEigth" className="collapse" aria-labelledby="headingEigth" data-parent="#accordion">
                <div className="card-body">Sim. Seus pontos disponíveis para resgate em dinheiro ou vale compras da loja expiram ao final de cada mês. Porém, seus pontos bônus, disponíveis para resgate de produtos em lojas parceiras do site sapo acumulativos e estes não expiram.</div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingNine">
                <h5 className="mb-0">
                  <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">Preciso emitir Nota Fiscal referente aos meus resgates em dinheiro?</button>
                </h5>
              </div>
              <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordion">
                <div className="card-body">Não. Já é emitido pela empresa contratada pela Obra Fácil, Nota Fiscal de todos os valores disponibilizados em pontos aos parceiros.</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {FooterHighlights()}
    </>
  )
}
