import React from 'react';
import { ApiLocal } from '../../constants'
import ReactHtmlParser from 'react-html-parser';
import FooterHighlights from '../footer/highlights';

export default function Professional(props) {
  const [partner, setPartner] = React.useState({ image: "", images: [] });

  React.useEffect(() => {
    ApiLocal("GET", `partners/${props.match.params.id}`)
      .then(response => { setPartner({ ...response.data }) })
    // .catch(response => { props.history.push("/404") })
  }, [props.match.params.id])

  return (
    <>
      <div className="top-interna img-wide" style={{ backgroundImage: "url(lib/assets/img/content/top-projetos.jpg)" }}>
        <div className="container position-relative">
          <div className="row h-100 d-flex align-items-center">
            <div className="col-md-3">
              <div className="campanha-logo">
                <img src="lib/assets/img/logo-campanha.png" alt='logo-campanha' />
              </div>
            </div>
            <div classNamame="col-md-9">
              <div className="page-title">
                <h1>Profissional</h1>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="page-content">
        <div className="container js-container position-relative pb-1">

          <h2 className="h2-base-r">
            <span>{partner.name}</span>
            <div className="js-r-side"></div>
          </h2>
          {partner.avatar && partner.avatar.url ?
            <div className="container" style={{ textAlign: "center" }}>
              <img src={partner.avatar.url} alt={partner.avatar.url.split('/').pop()} />
            </div> : <></>}
          <div className="text">{ReactHtmlParser(partner.aboutme)}
            {partner.site ? <><br />Site: <a href={partner.site} target="_blank" rel="noopener noreferrer">{partner.site}</a></> : ""}
            {partner.instagram ? <><br />Instagram: <a href={`https://www.instagram.com/${partner.instagram.replace("@", "")}`} target="_blank" rel="noopener noreferrer">{partner.instagram}</a></> : ""}
            {partner.email && partner.email.email ? <><br /><a href={`mailto:${partner.email.email}?subject=Obrafacil: Quero falar com você&body=Olá, conheci você através do site https://obrafacilmais.com.br. Estou interessado em conhecer mais sobre o seu trabalho.`}> {partner.email.email}</a></> : ""}
            {partner.address && partner.address.city_name ? <><br />{partner.address.city_name} - {partner.address.state_name}</> : ""}
          </div>
        </div>
      </div>

      {/* <h2 className="h2-base-r d-flex justify-content-between align-items-center">
        <div className="">
          <span> <strong>Projetos</strong></span>
        </div>
        <div className="navGal">
          {project.images.map((i, e) => <a key={e} href="" data-sh={`${e + 1}`}>{e + 1}</a>)}
        </div>
      </h2>

      <div className="gal-item-sh">
        {project.images.map((images, index) =>
          <div key={index} className="item" id={`gal-${index + 1}`}>
            <div className="row">
              {images.map((image, index2) => <div key={index2} className="col-lg-6 mb-4"><a data-fancybox={`gallery-${index + 1}`} href={image.attachment.url}><div style={{ backgroundImage: `url(${image.attachment.url}`, height: "270px", backgroundSize: 'auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} /></a></div>)}
            </div>
          </div>
        )}
      </div> */}

      <div className="page-content">
        <div className="container js-container position-relative pt-1">
          <div className="area-bar-social">
            <div className="bar-social">
              <a href="https://www.facebook.com/Obrafacil/" rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook-f"></i></a>
              <a href="https://www.linkedin.com/company/lojaobrafacil/" rel="noopener noreferrer" target="_blank"><i className="fab fa-linkedin"></i></a>
              <a href="https://www.youtube.com/channel/UC-YzYZyjTNNWnWzXHecA8mg" rel="noopener noreferrer" target="_blank"><i className="fab fa-youtube"></i></a>
              <a href="https://www.instagram.com/lojaobrafacil/" rel="noopener noreferrer" target="_blank"><i className="fab fa-instagram"></i></a>
            </div>
            <div className="lb"><strong>compartilhe</strong> este conteúdo</div>
          </div>
        </div>
      </div>
      {FooterHighlights()}
    </>
  )
}
