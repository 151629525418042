import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../../../helpers/cropImage';

const useStyles = makeStyles(theme => ({
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333'
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
}));


export const AvatarComponent = forwardRef(({ loading }, ref) => {
  const classes = useStyles();
  const [image, setImage] = React.useState();
  const [file, setFile] = React.useState({});
  const [crop, setCrop] = useState({ x: 0, y: 1 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  useImperativeHandle(ref, () => ({
    async getImage() {
      return ({
        blob: await getCroppedImg(
          image,
          croppedAreaPixels
        ),
        name: file.name
      })
    }
  }))

  const handleChangeImage = e => {
    if (e && e.target && e.target.files.length) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setFile({ ...file, name: e.target.files[0].name });
    }
  };

  return <Grid container spacing={2} className={classes.container} noValidate autoComplete="off">
    <Grid item xs={12} md={12}>
      <div className={classes.cropContainer}>
        <Cropper
          showGrid={!!image}
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={4 / 4}
          onCropChange={setCrop}
          onCropAreaChange={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
    </Grid>
    <Grid item xs={12} md={12}>
      <input disabled={loading} type="file" name="myImage" accept="image/*" onChange={handleChangeImage} />
    </Grid>
  </Grid>
})
