
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  set_loading: ["loading"]
})

const INITIAL_STATE = {
  loading: false
}

const set_loading = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loading: action.loading
  }
}

export default createReducer(INITIAL_STATE,
  {
    [Types.SET_LOADING]: set_loading,
  })