import { combineReducers } from "redux";
import auth from './auth';
import helper from './helper';
import partner from './partner';
import stores from './stores';
import { connectRouter } from 'connected-react-router'

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  helper,
  partner,
  stores,
})
