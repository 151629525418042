import React from 'react';
import { ApiLocal } from '../../constants'
import ReactHtmlParser from 'react-html-parser';
import FooterHighlights from '../footer/highlights';

const MONTHS = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

export default function Events(props) {
  const [events, setEvents] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    ApiLocal("GET", `highlights/event?page=${page}`)
      .then(response => { setEvents([...events, ...response.data]); setTotal(response.headers.total) })
  }, [page])

  return (
    <>
      <div className="top-interna img-wide" style={{ backgroundImage: "url(lib/assets/img/content/top-eventos.jpg)" }}>
        <div className="container position-relative">
          <div className="row h-100 d-flex align-items-center">
            <div className="col-md-3">
              <div className="campanha-logo">
                <img src="lib/assets/img/logo-campanha.png" alt='logo-campanha' />
              </div>
            </div>
            <div className="col-md-9">
              <div className="page-title">
                <h1>Eventos</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container pb-0 js-container">
          <h2 className="mb-4">Próximos <strong>Eventos</strong></h2>
        </div>
      </div>
      <div className="">
        {events.length !== 0 ?
          <>
            {events.map((item, index) => {
              return <div className="page-content  overflow " key={index}>
                <div className="container pt-0 pb-0 position-relative">
                  <div className="lista-eventos">
                    <div className="item">
                      <div className="imagem" style={{ height: "fit-content" }}><img src={item.image.url} alt={item.image.url.split('/').pop()} /></div>
                      <div className="data">
                        {(() => {
                          let start = item.starts_in ? new Date(item.starts_in) : undefined,
                            expires = item.expires_in ? new Date(item.expires_in) : undefined;
                          if (start) {
                            let day = start.getUTCDate(),
                              month = start.getUTCMonth(),
                              startsHour = start.getUTCHours(),
                              startsMinutes = start.getUTCMinutes();
                            return <>
                              <div className="dia">{day}</div>
                              <div className="mes">{MONTHS[month]}</div>
                              <div className="hora">{
                                startsHour ? <>
                                  <span style={{ whiteSpace: "nowrap" }}>
                                    {expires ? "das" : "às"} {startsHour}h{startsMinutes === 0 ? "00" : startsMinutes}
                                  </span>
                                  <br />
                                  <span style={{ whiteSpace: "nowrap" }}>
                                    {expires ? ` às ${expires.getHours()}h${expires.getUTCMinutes() === 0 ? "00" : expires.getUTCMinutes()}` : ""}
                                  </span> </> : ""}
                              </div>
                            </>
                          } else {
                            let year = new Date(item.created_at).getUTCFullYear().toString();
                            if (year)
                              return <>
                                <div className="dia">{year.substring(0, 2)}<br />{year.substring(2, 4)}</div>
                                <div className="mes"></div>
                              </>
                          }
                        })()}
                      </div>
                      <div className="descricao">
                        <div className="l-title">{item.title}</div>
                        <div className="l-desc">{ReactHtmlParser(item.subtitle)}</div>
                        <div className="l-link">
                          <a href={`/eventos/${item.id}`}>ver <strong>mais</strong></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            })}
            {total > 0 && events.length > total ?
              <div className="row justify-content-center m-b-20 m-t-20">
                <button className="btn" style={{ bottom: "0px", left: "50%", background: "#f6be18" }} onClick={e => setPage(page + 1)}>Carregar mais</button>
              </div> : <></>}
          </>
          :
          <div className="container">
            <div className="row justify-content-center p-t-100 p-b-100">
              <div className="col-md-4">
                <img src="https://hubcoapp-images.s3-sa-east-1.amazonaws.com/logos/obrafacil-logo.png" alt="loading" className="card-img-top op-0-6" />
              </div>
            </div>
          </div>
        }
      </div>
      {FooterHighlights()}
    </>
  )
}
