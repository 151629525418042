import React from 'react';
import { ApiLocal, chunkArray } from '../../constants'
import ReactHtmlParser from 'react-html-parser';
import FooterHighlights from '../footer/highlights';
import * as $ from 'jquery';

export default function Event(props) {
  const [event, setEvent] = React.useState({ image: "", images: [] });

  React.useEffect(() => {
    ApiLocal("GET", `events/${props.match.params.id}`)
      .then(response => { setEvent({ ...response.data, images: groupImages(response.data.images) }) })
      .catch(response => { props.history.push("/404") })
  }, [])


  $(function () {
    $('.navGal a:first-child').addClass('active');
    $('.navGal a').click(function (e) {
      e.preventDefault();
      $('.navGal a').removeClass('active');
      $(this).addClass('active');
      var itemShow = $(this).attr('data-sh');
      $('.gal-item-sh .item').hide();
      $('.gal-item-sh #gal-' + itemShow).fadeIn();
    });
  });

  const groupImages = (images) => images && images.length ? chunkArray(images, 10) : []

  return (
    <>
      <div className="top-interna img-wide" style={{ backgroundImage: "url(lib/assets/img/content/top-projetos.jpg)" }}>
        <div className="container position-relative">
          <div className="row h-100 d-flex align-items-center">
            <div className="col-md-3">
              <div className="campanha-logo">
                <img alt="logocapanha" src="lib/assets/img/logo-campanha.png" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="page-title">
                <h1>Evento</h1>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="page-content">
        <div className="container js-container position-relative pb-1">

          <h2 className="h2-base-r">
            <span>{event.title}</span>
            <div className="js-r-side"></div>
          </h2>

          <p>{event.subtitle}</p>
        </div>
      </div>
      {
        event.image && event.image.url ?
          <div className="img-inner-wide">
            <div className="img-wide" style={{ backgroundImage: `url(${event.image.url})` }}></div>
          </div> :
          <></>
      }

      <div className="page-content">
        <div className="container js-container position-relative pt-1">
          {ReactHtmlParser(event.metadata)}
          {event.images.length ? <>

            <p>&nbsp;</p>
            <h2 className="h2-base-r d-flex justify-content-between align-items-center">
              <div className="">
                <span>Galeria <strong>de fotos</strong></span>
              </div>
              <div className="navGal">
                {event.images.map((i, e) => <a key={e} href="" data-sh={`${e + 1}`}>{e + 1}</a>)}
              </div>
            </h2>

            <div className="gal-item-sh">
              {event.images.map((images, index) =>
                <div key={index} className="item" id={`gal-${index + 1}`}>
                  <div className="row">
                    {images.map((image, index2) => <div key={index2} className="col-lg-6 mb-4"><a data-fancybox={`gallery-${index + 1}`} href={image.attachment.url}><div style={{ backgroundImage: `url(${image.attachment.url}`, height: "270px", backgroundSize: 'auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} /></a></div>)}
                  </div>
                </div>
              )}
            </div>
          </> : <></>}
          <div className="area-bar-social">
            <div className="bar-social">
              <a href="https://www.facebook.com/Obrafacil/" rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook-f"></i></a>
              <a href="https://www.linkedin.com/company/lojaobrafacil/" rel="noopener noreferrer" target="_blank"><i className="fab fa-linkedin"></i></a>
              <a href="https://www.youtube.com/channel/UC-YzYZyjTNNWnWzXHecA8mg" rel="noopener noreferrer" target="_blank"><i className="fab fa-youtube"></i></a>
              <a href="https://www.instagram.com/lojaobrafacil/" rel="noopener noreferrer" target="_blank"><i className="fab fa-instagram"></i></a>
            </div>
            <div className="lb"><strong>compartilhe</strong> este conteúdo</div>
          </div>
        </div>
      </div>
      {FooterHighlights()}
    </>
  )
}
