import { Component } from "react"

export const Colors = {
  dark: "#465e80",
  white: "white",
  light: "#F6BB41",
  shadow: "rgba(25, 82, 158, 0.15)"
}

export const Styles = {
  font: {
    fontFamily: "Ubuntu, 'Open Sans', times, roman, serif"
  },
  th: { backgroundColor: Colors.dark, borderColor: Colors.dark },
  shadow: { boxShadow: `1px 1px 30px 10px ${Colors.shadow}` },
}
export class If extends Component {
  render() {
    return this.props.object ? this.props.children : ' - '
  }
}

export const isEmail = (email) => {
  let reg = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
  return reg.test(email) !== false;
}

export const titleize = (str) => {
  let val = str.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
  [" Da ", " Do ", " Das ", " Dos ", " De ", "Da ", "Do ", "Das ", "Dos ", "De ", " E "].map(item => {
    if (val.includes(item))
      val = val.replace(item, item.toLowerCase());
  })
  return val;
};

export const clearEmpty = (obj) => {
  for (let i = 0; i < obj.length; i++) {
    if (!obj[i])
      delete obj[i];
  }
  return obj
}
