import React from 'react';
import Navbar from './navbar';
import { Route, Redirect } from 'react-router-dom';
import Dashboard from './partner';
import Footer from './pages/footer';
import { SignUpPage } from "./pages/sign_up";
import Highlights from "./pages/highlights";
import Highlight from "./pages/highlights/details";
import Indication from './pages/indication';
import Events from './pages/events';
import Professionals from './pages/professionals';
import Professional from './pages/professionals/details';
import { ApiLocal } from './constants';
import Welcome from './pages';
import useScript from './helpers/scripts';
import { useSelector } from 'react-redux';
import Contact from './pages/contact';
import Faq from './pages/faq';
import Partner from './pages/partner';
import Event from './pages/events/details';
import Project from './pages/projects/details';
import Projects from './pages/projects';
import Me from './partner/me';
import Error404 from './pages/errors/404';
import Warnings from './warnings';
import { PrivacyPolicy } from './components/PrivacyPolicy';


export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => localStorage.getItem('access-token') ? <Component {...props} /> : <Redirect to="/" />}
  />
)

export default function App() {
  const { loading } = useSelector(state => state.helper)
  useScript("lib/assets/js/main.js")
  React.useEffect(() => {
    if (!localStorage.getItem('st') || !localStorage.getItem('ct') || !localStorage.getItem('bk'))
      ApiLocal('GET', 'all').then(response => {
        localStorage.setItem('bk', JSON.stringify(response.data.banks))
        localStorage.setItem('st', JSON.stringify(response.data.states))
        localStorage.setItem('ct', JSON.stringify(response.data.cities))
      });
  }, [])

  React.useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [window.location.pathname]);

  return (
    <div className={loading ? "signup-loading" : ""}>
      <Warnings />
      <Navbar />
      <div>
        {
          localStorage.getItem('access-token') ?
            "" :
            <>
              <Route exact path="/cadastrar" component={SignUpPage} />
            </>
        }
        <Route exact path="/" component={Welcome} />
        <Route exact path="/indique" component={Indication} />
        {/* <Route exact path="/campanhas" component={Campain} /> */}
        <Route exact path="/destaques" component={Highlights} />
        <Route exact path="/destaques/:id" component={Highlight} />
        <Route exact path="/eventos" component={Events} />
        <Route exact path="/eventos/:id" component={Event} />
        <Route exact path="/profissionais" component={Professionals} />
        <Route exact path="/profissionais/:id" component={Professional} />
        <Route exact path="/contato" component={Contact} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path='/parceiro/:id' component={Partner} />
        <Route exact path='/projetos' component={Projects} />
        <Route exact path='/projetos/:id' component={Project} />
        <Route exact path='/404' component={Error404} />
        <Route exact path='/politica-de-privacidade' component={PrivacyPolicy} />
        <PrivateRoute path='/dashboard' component={Dashboard} />
        <PrivateRoute path='/me' component={Me} />
      </div>
      <Footer />
    </div>
  )
}
