import { Card, IconButton, makeStyles } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { AddCircle, RemoveCircle } from "@material-ui/icons";
import React, { useEffect, useState } from "react"
import ReactInputMask from "react-input-mask"
import MaterialDate from "../../../../helpers/datePicker";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  removeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
    },
  },
}));

export const MembersListComponent = ({ handleChangeMember, loading }) => {
  const classes = useStyles();
  const [members, setMembers] = useState([{ member_type: "partner" }]);

  const handleChange = index => input => e => {
    setMembers(members.map((member, i) => i === index ? { ...member, [input]: e.target.value } : member))
  }

  const handleChangeDate = index => input => e => {
    handleChange(index)(input)({ target: { value: e ? e._d : null } })
  }

  const addMember = e => {
    setMembers([...members, { member_type: "partner" }])
  }

  const removeMember = index => e => {
    if (members.length > 1)
      setMembers(members.filter((e, i) => i !== index))
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      handleChangeMember({ target: { value: members } })
    }, 1000)
    return () => {
      clearTimeout(timer);
    };
  }, [members])

  return (<div style={{ paddingBottom: '10px' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'center' }}>
      <h6 className="mb-4"><strong>Membros</strong> -
        Nessa lista você pode adicionar os dados dos sócios e membros, assim, podemos personalizar os nossos mimos para vocês</h6>
      <IconButton onClick={addMember} disabled={loading || members.length > 2}>
        <AddCircle onClick={addMember} style={{ color: members.length > 2 ? green[400] : green[700] }} />
      </IconButton>
    </div>
    <Card>
      {members?.map((member, i) => <div key={i} className="row" style={{ padding: '10px', borderBottom: 'gray 1px solid' }}>
        <div className="col-md-12">
          <div className="row" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
            <div className={classes.removeButton}>
              <IconButton onClick={removeMember(i)} disabled={loading || members.length < 2}>
                <RemoveCircle style={{ color: members.length < 2 ? red[400] : red[700] }} />
              </IconButton>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className="form-group input-arrow w-100">
                <label id={`label-members-${i}-name`}>Nome<b style={{ color: "red" }}>*</b></label>
                <input
                  type="text"
                  name="name"
                  id={`field-members-${i}-name`}
                  className="form-input"
                  autoComplete="off"
                  value={member.name}
                  onChange={handleChange(i)('name')}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group input-arrow w-100">
                <label id={`label-members-${i}-birth_date`} style={{ whiteSpace: 'nowrap' }}>Aniversário Dia/Mês<b style={{ color: "red" }}>*</b></label>
                <MaterialDate
                  id={`field-members-${i}-birth_date`}
                  value={member.birth_date}
                  format="DD/MM"
                  onChange={handleChangeDate(i)('birth_date')}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group input-arrow w-100">
                <label id={`label-members-${i}-email`}>email<b style={{ color: "red" }}>*</b></label>
                <input
                  type="text"
                  name="email"
                  id={`field-members-${i}-email`}
                  className="form-input"
                  autoComplete="off"
                  value={member.email}
                  onChange={handleChange(i)('email')}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group input-arrow w-100">
                <label id={`label-members-${i}-phone`}>celular<b style={{ color: "red" }}>*</b></label>
                <ReactInputMask
                  id={`field-members-${i}-phone`}
                  type="text"
                  mask="(99) 99999-9999"
                  maskChar=''
                  name="phone"
                  className="form-input"
                  value={member.phone}
                  onChange={handleChange(i)('phone')}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group input-arrow w-100">
                <label id={`label-members-${i}-member-type`}>Tipo<b style={{ color: "red" }}>*</b></label>
                <select
                  name=""
                  id={`field-members-${i}-member-type`}
                  className="form-input cps custom-select"
                  value={member.member_type}
                  onChange={handleChange(i)('member_type')}
                  disabled={loading}
                >
                  <option value="partner">Sócio</option>
                  <option value="assistant">Assistente</option>
                  <option value="contact">Contato</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </Card>
  </div>
  )
}
