import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  login: ['email', 'password'],
  reqLogin: ['email', 'password'],
  logout: []
})

const INITIAL_STATE = {
  logedIn: false,
  logedOut: true,
}

const reqLogin = (state = INITIAL_STATE, action) => {
  return true
}

const login = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    logedIn: action.payload.logedIn,
    authToke: action.payload.authToken,
    userEmail: action.payload.userEmail
  }
}

const logout = (state = INITIAL_STATE, action) => alert('request logout')


export default  createReducer(INITIAL_STATE, {
  [Types.REQ_LOGIN]: reqLogin,
  [Types.LOGIN]: login,
  [Types.LOGOUT]: logout
})
