import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApiLocal } from '../constants';
import Swal from 'sweetalert2';
import { titleize } from '../helpers';
import FooterHighlights from '../pages/footer/highlights'
import * as $ from 'jquery'


export default function Me() {
  const dispatch = useDispatch()
  const partner = useSelector(state => state.partner);
  const [state, setState] = React.useState({
    current_password: '',
    password: '',
    password_confirmation: '',
    disabled: false
  })

  React.useEffect(() => {
    dispatch({ type: "PARTNER_HANDLE_CHANGE", partner: JSON.parse(sessionStorage.getItem('me')) })
  }, [])

  const [typePass, setTypePass] = React.useState("password")

  const changeType = () => setTypePass(typePass === "password" ? "text" : "password")

  const handleChange = (key) => (event) => {
    setState({ ...state, [key]: event.target.value })
  }
  const newPassword = (e) => {
    e.preventDefault()
    ApiLocal('PUT', 'selfs/password', { current_password: state.current_password, password: state.password, password_confirmation: state.password_confirmation })
      .then(response => Swal.fire(
        '200!',
        response.data.success,
        'success'
      ).then(() => {
        $('#field-senha-atual').val("");
        $('#field-senha').val("");
        $('#field-confirmacao').val("");
        setState({ current_password: '', password: '', password_confirmation: '', disabled: false })
      }))
      .catch(response => Swal.fire(
        'Oops...',
        Object.values(response.response.data.errors).join(","),
        'error'
      ).then(() => setState({ disabled: false })))
  }

  return (<> <div className="top-interna img-wide" style={{ backgroundImage: "url(lib/assets/img/content/top-cadastro.jpg)" }}>
    <div className="container position-relative">
      <div className="row h-100 d-flex align-items-center">
        <div className="col-md-4">
          <div className="campanha-logo">
            <img src="lib/assets/img/logo-campanha.png" alt='logo-campanha' />
          </div>
        </div>
        <div className="col-md-8">
          <div className="page-title">
            <h1>Meus <strong>dados</strong></h1>
          </div>
        </div>
      </div>
    </div>
  </div>


    <form className="tpl-form-1 form-cadastro p-0">

      <div className="page-content">
        <div className="container no-arrow-b js-container position-relative dif-form-group pb-40">

          <h2 className="mb-4">Dados pessoais / bancários</h2>

          <div className="mt-4 mb-4">
            <div className="form-group">
              <label className="" for="field-nome">nome do parceiro</label>
              <input readOnly name="nome" id="field-nome" className="form-input" type="text" autocomplete="off" value={partner.name} />
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group input-arrow w-100">
                  <label className="" id="label-cpf-cnpj" for="field-cpf-cnpj">cpf ou cnpj</label>
                  <input readOnly name="cpf-cnpj" id="field-cpf-cnpj" className="form-input" type="text" autocomplete="off" value={partner.federal_registration} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group input-arrow w-100">
                  <label className="" id="label-rg-ie" for="field-rg-ie">rg ou inscrição estadual</label>
                  <input readOnly name="rg-ie" id="field-rg-ie" className="form-input" type="text" autocomplete="off" value={partner.state_registration} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group input-arrow w-100">
                  <label className="" id="label-banco" for="field-banco">banco</label>
                  <input readOnly name="banco" id="field-banco" className="form-input" type="text" autocomplete="off" value={partner.bank_name} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group input-arrow w-100">
                  <label className="" id="label-agencia" for="field-agencia">agência</label>
                  <input readOnly name="agencia" id="field-agencia" className="form-input" type="text" autocomplete="off" value={partner.agency} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group input-arrow w-100">
                  <label className="" id="label-conta" for="field-conta">conta</label>
                  <input readOnly name="conta" id="field-conta" className="form-input" type="text" autocomplete="off" value={partner.account} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group input-arrow w-100">
                  <label className="" id="label-favorecido" for="field-favorecido">favorecido</label>
                  <input readOnly name="favorecido" id="field-favorecido" className="form-input" type="text" autocomplete="off" value={partner.favored} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group input-arrow w-100">
                  <label className="" id="label-cpf-cnpj-favorecido" for="field-cpf-cnpj-favorecido">cpf ou cnpj do favorecido</label>
                  <input readOnly name="cpf-cnpj-favorecido" id="field-cpf-cnpj-favorecido" className="form-input" type="text" autocomplete="off" value={partner.favored_federal_registration} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group input-arrow w-100">
                  <label className="" id="label-registro-cau-abd" for="field-registro-cau-abd">registro cau abd</label>
                  <input readOnly name="registro-cau-abd" id="field-registro-cau-abd" className="form-input" type="text" autocomplete="off" value={partner.register} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group input-arrow w-100">
                  <label className="" id="label-site" for="field-site">site</label>
                  <input readOnly name="site" id="field-site" className="form-input" type="text" autocomplete="off" value={partner.site} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sessao-A001">
          <div className="page-content">
            <div className="container no-arrow-b position-relative dif-form-group">
              <h2 className="mb-4">Endereços</h2>
              {partner.addresses.map(address =>
                <div key={address.id}>
                  <h6 className="mb-4"><strong>{titleize(address.address_type_name)}</strong></h6>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group input-arrow w-100">
                        <label className="" id="label-res-endereco" for="field-res-endereco">endereço</label>
                        <input readOnly name="res-endereco" id="field-res-endereco" className="form-input" type="text" autocomplete="off" value={address.street} />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group input-arrow w-100">
                        <label className="" id="label-res-numero" for="field-res-numero">número</label>
                        <input readOnly name="res-numero" id="field-res-numero" className="form-input" type="text" autocomplete="off" value={address.number} />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group input-arrow w-100">
                        <label className="" id="label-res-complemento" for="field-res-complemento">complemento</label>
                        <input readOnly name="res-complemento" id="field-res-complemento" className="form-input" type="text" autocomplete="off" value={address.complement} />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group input-arrow w-100">
                        <label className="" id="label-res-bairro" for="field-res-bairro">bairro</label>
                        <input readOnly name="res-bairro" id="field-res-bairro" className="form-input" type="text" autocomplete="off" value={address.neighborhood} />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group input-arrow w-100">
                        <label className="" id="label-res-cep" for="field-res-cep">cep</label>
                        <input readOnly name="res-cep" id="field-res-cep" className="form-input cep" type="text" autocomplete="off" value={address.zipcode} />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group input-arrow w-100">
                        <label className="" id="label-res-tipo-endereco" for="field-res-tipo-endereco">tipo de endereço</label>
                        <input readOnly name="tipo-res-endereco" id="field-res-tipo-endereco" className="form-input" type="text" autocomplete="off" value={titleize(address.address_type_name)} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group input-arrow w-100">
                        <label className="" id="label-res-cidade" for="field-res-cidade">cidade</label>
                        <input readOnly name="registro-res-cidade" id="field-res-cidade" className="form-input" type="text" autocomplete="off" value={address.city_name} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group input-arrow w-100">
                        <label className="" id="label-res-estado" for="field-res-estado">estado</label>
                        <input readOnly name="res-estado" id="field-res-estado" className="form-input" type="text" autocomplete="off" value={address.state_name} />
                      </div>
                    </div>
                  </div>
                  <div className="">&nbsp;</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="page-content">
          <div className="container no-arrow-b position-relative dif-form-group">
            <h2 className="mb-4">Telefones</h2>
            {partner.phones.map(phone =>
              <div className="row" key={phone.id}>
                <div className="col-md-3">
                  <div className="form-group input-arrow w-100">
                    <label className="" id="label-tipo-telefone-1" for="field-tipo-telefone-1">tipo de telefone</label>
                    <input readOnly name="registro-tipo-telefone-1" id="field-tipo-telefone-1" className="form-input" type="text" autocomplete="off" value={phone.phone_type_name} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group input-arrow w-100">
                    <label className="" id="label-ddd-telefone-1" for="field-ddd-telefone-1">ddd + telefone</label>
                    <input readOnly name="ddd-telefone-1" id="field-ddd-telefone-1" className="form-input" type="text" autocomplete="off" value={phone.phone} />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group input-arrow w-100">
                    <label className="" id="label-resp-telefone-1" for="field-resp-telefone-1">responsável pelo telefone</label>
                    <input readOnly name="resp-telefone-1" id="field-resp-telefone-1" className="form-input" type="text" autocomplete="off" value={phone.contact} />
                  </div>
                </div>
              </div>
            )}


            <div className="mb-5">&nbsp;</div>
            <h2 className="mb-4">E-mails</h2>


            {partner.emails.map(email =>
              <div className="row" key={email.id}>
                <div className="col-md-3">
                  <div className="form-group input-arrow w-100">
                    <label className="" id="label-tipo-telefone-1" for="field-tipo-telefone-1">tipo de telefone</label>
                    <input readOnly name="registro-tipo-telefone-1" id="field-tipo-telefone-1" className="form-input" type="text" autocomplete="off" value={email.email_type_name} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group input-arrow w-100">
                    <label className="" id="label-email" for="field-email">email</label>
                    <input readOnly name="email" id="field-email" className="form-input" type="text" autocomplete="off" value={email.email} />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group input-arrow w-100">
                    <label className="" id="label-resp-telefone-1" for="field-resp-telefone-1">responsável pelo email</label>
                    <input readOnly name="resp-telefone-1" id="field-resp-telefone-1" className="form-input" type="text" autocomplete="off" value={email.contact} />
                  </div>
                </div>
              </div>
            )}
            <div className="mb-5">&nbsp;</div>
            <h2 className="mb-4">Senha</h2>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group input-arrow w-100">
                  <label className="form-label" id="label-senha-atual" htmlFor="field-senha-atual">senha atual</label>
                  <input type={typePass} name="old-password" id="field-senha-atual" className="form-input" autoComplete="off" onChange={handleChange('current_password')} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group input-arrow w-100">
                  <label className="form-label" id="label-senha" htmlFor="field-senha">nova senha</label>
                  <input type={typePass} name="new-password" id="field-senha" className="form-input" autoComplete="off" onChange={handleChange('password')} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group input-arrow w-100">
                  <label className="form-label" id="label-confirmacao" htmlFor="field-senha1">confirmar nova senha</label>
                  <input type={typePass} name="new-password-confirmation" id="field-confirmacao" className="form-input" autoComplete="off" onChange={handleChange('password_confirmation')} />
                </div>
              </div>
              <img alt="seen-password" src={`lib/assets/img/${typePass === 'text' ? 'eye-solid.svg' : 'eye-slash-solid.svg'}`} width="20" onClick={changeType} />
            </div>


            <div class="page-content">
              <div class="container pt-0 position-relative">
                <div class="row">
                  <div class="col-lg-7 d-flex justify-content-end align-items-center">
                  </div>
                  <div class="col-lg-5 position-relative">
                    <button type="submit" class="btn btn-block bt-cadastro" onClick={newPassword}><strong>ALTERAR</strong> SENHA</button>
                    <div class="js-r-side"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    {FooterHighlights()}
  </>)
}
