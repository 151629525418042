import React from 'react'
import { ApiLocal } from '../constants'
import Swal from 'sweetalert2'
import InputMask from 'react-input-mask'
import { ClipLoader } from 'react-spinners'
import { useDispatch } from 'react-redux'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { IconButton } from 'material-ui'
import { Input } from '@material-ui/core'


export default function FormLogin() {
  const dispatch = useDispatch()
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [canSee, setCanSee] = React.useState(false);

  const togglePassword = () => setCanSee(!canSee)

  const signIn = e => {
    e.preventDefault()
    setLoading(true)

    if (password || username) {
      setTimeout(() => {
        ApiLocal('POST', 'auth/sign_in', { federal_registration: username.replace(/[/.-]/g, ""), password: password })
          .then(response => {
            localStorage.setItem('access-token', response.headers['access-token'])
            localStorage.setItem('client', response.headers['client'])
            localStorage.setItem('uid', response.headers['uid'])
            localStorage.setItem('me', JSON.stringify(response.data.data))
            dispatch({ type: "PARTNER_HANDLE_CHANGE", partner: response.data.data })
            window.location.href = '/dashboard'
          }).catch(response => Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: response.response.data.errors,
          }, setLoading(false)))
      })
    } else {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: "Favor inserir usuário e senha",
      }, setLoading(false))
    }
  }

  const forgotPassword = e => {
    Swal.fire({
      title: 'Qual seu CPF / CNPJ?',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        maxlength: 14
      },
      showCancelButton: true,
      confirmButtonText: 'Confirma?',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return ApiLocal("POST", `forgot_password/${login}`)
          .then(response => {
            if (response.status === 200) {
              return response.data
            } else
              Swal.showValidationMessage(
                response.data.errors
              )
          })
          .catch(error => {
            Swal.showValidationMessage(
              error.response.data.errors
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          type: "success",
          text: result.value.success,
        })
      }
    })
  }

  return (
    <form className="tpl-form-1" onSubmit={signIn}>
      <div className="form-group">
        <label className="form-label" htmlFor="field-cpf-cnpj" style={{ paddingLeft: "10px" }}>CPF / CNPJ</label>
        <InputMask
          type="text"
          name="cpf-cnpj"
          id="field-cpf-cnpj"
          className="form-input ico-r-user"
          disabled={loading}
          style={{ borderRadius: "5px", height: "40px" }}
          mask={username ? username.length <= 14 ? "999.999.999-999" : "99.999.999/9999-99" : ""}
          maskChar=""
          value={username}
          onChange={e => setUsername(e.target.value)} />
      </div>
      <div className="form-group">
        <label className="form-label" htmlFor="field-senha" style={{ paddingLeft: "10px" }}>SENHA</label>
        <Input
          disabled={loading}
          name="senha"
          id="field-senha"
          className="form-input ico-r-phone"
          autoComplete="off"
          type={canSee ? "text" : "password"}
          value={password}
          onChange={e => setPassword(e.target.value)}
          style={{ borderRadius: "5px", height: "40px" }}
          endAdornment={
            canSee ? <VisibilityOff onClick={togglePassword} style={{ cursor: "pointer" }} /> : <Visibility onClick={togglePassword} style={{ cursor: "pointer" }} />
          }
        />
      </div>
      <div className="esqueci" ><span className="text-white" style={{ cursor: "pointer" }} onClick={forgotPassword}>ESQUECI MINHA SENHA</span></div>
      <button type="submit"
        disabled={loading}
        className="btn btn-block">
        {!loading ? "ACESSAR" : <ClipLoader
          sizeUnit={"px"}
          size={30}
          color={'white'}
          loading={loading}
        />}</button>
    </form>
  )
}
