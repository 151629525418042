import Axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ApiUrl = (path) => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return `https://api.hubcoapp.com.br/partner/${path}`
    case 'development':
      return `http://localhost:3001/partner/${path}`
    case 'staging':
      return `https://partner-stg.hubcoapp.com.br/${path}`
    default:
      return null
  }
}

export const signOut = () => {
  ApiLocal('DELETE', `auth/sign_out`)
    .then(() => { localStorage.clear(); window.location.href = "/" })
    .catch(() => { localStorage.clear(); window.location.href = "/" })
}

export const ApiLocal = (type, component, data, headers) =>
  Axios({
    method: type,
    url: ApiUrl(component),
    headers: headers ? headers : {
      'access-token': localStorage.getItem('access-token'),
      'client': localStorage.getItem('client'),
      'uid': localStorage.getItem('uid'),
    },
    data
  });

export const Api = (type, component, data, headers) =>
  Axios({
    method: type,
    url: process.env.NODE_ENV === 'production' ? `https://api.hubcoapp.com.br/${component}` : `http://localhost:3001/${component}`,
    headers: headers ? headers : {
      'access-token': localStorage.getItem('access-token'),
      'client': localStorage.getItem('client'),
      'uid': localStorage.getItem('uid'),
    },
    data
  });

export const HandleToast = (data, cb) => {
  if (data) {
    data = data.response ? data.response : data
    switch (data.status) {
      case 200:
        toast.update(toast("Uhul, deu tudo certo!!"), { type: toast.TYPE.SUCCESS })
        setTimeout(cb, 3000)
        break
      case 201:
        toast.update(toast("Uhul, deu tudo certo!!"), { type: toast.TYPE.SUCCESS })
        setTimeout(cb, 3000)
        break
      case 204:
        toast.update(toast("Deletado com sucesso! Vou atualizar a pagina pra você"), { type: toast.TYPE.SUCCESS })
        setTimeout(cb, 3000)
        break
      case 401:
        toast.update(toast("Acesso não Autorizado"), { type: toast.TYPE.ERROR })
        break
      case 422:
        Object.keys(data.data.errors).map(key => key === "full_messages" ? "" : toast.update(toast(`${key}: ${data.data.errors[key]}`), { type: toast.TYPE.ERROR }))
        break
      default: toast.update(toast("não foi possível processar sua requisição. Favor tente novamente mais tarde"), { type: toast.TYPE.ERROR })
    }
  } else {
    toast.update(toast("Houve um erro ao se comunicar com o servidor. tente novamente, se persistir chamar o analista"), { type: toast.TYPE.ERROR })
    data ? console.error(data.data) : console.error("Servidor incomunicável")
  }
}

export const chunkArray = (myArray, chunk_size) => {
  var results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }

  return results;
}
