
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  partner_handle_change: [
    'id', 'name', 'federal_registration', 'state_registration', 'agency', 'account',
    'favored', 'favored_federal_registration', 'bank_name', 'register', 'site',
    'addresses', 'phones', 'emails', 'commissions', 'money_pi', 'points_pi',
    'premio_ideal_rescue', 'avatar'],
})

const INITIAL_STATE = {
  id: "",
  name: "",
  federal_registration: "",
  state_registration: "",
  agency: "",
  account: "",
  favored: "",
  favored_federal_registration: "",
  bank_name: "",
  register: "",
  site: "",
  addresses: [],
  phones: [],
  emails: [],
  commissions: [],
  money_pi: "",
  points_pi: "",
  avatar: {},
  coupon: {},
  premio_ideal_rescue: {}
}

const handle_change = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.partner
  }
}


export default createReducer(INITIAL_STATE,
  {
    [Types.PARTNER_HANDLE_CHANGE]: handle_change,
  })