import React from 'react';
import { ApiLocal } from '../../constants';
import { Link } from 'react-router-dom';

export default function FooterHighlights() {
	const [highlights, setHighlights] = React.useState([])

	React.useEffect(() => {
		ApiLocal("GET", "highlights?kind=normal,event&per_page=3")
			.then(response => setHighlights(response.data))
	}, [])

	return highlights && highlights.length ?

		<div className="sessao-t-b3 m2">
			<div className="container position-relative">
				<div className="row d-flex align-items-center">
					<div className="col-lg-4">
						<div className="s-titulo-area">
							<div className="s-titulo">DESTAQUES OBRA FÁCIL MAIS</div>
						</div>
					</div>
					<div className="col-lg-8">
						<div className="row x-row">
							{highlights.map(item =>
								<div className="col-lg-4" key={item.id}>
									<div className="item">
										<Link to={item.kind === "event" ? "/eventos" : "/destaques"}>
											<div className="titulo">{item.title}</div>
											<div className="imagem"><img src={item.image.small} alt={item.image.small.split('/').pop()} /></div>
											{item.subtitle ? <div className="chamada" style={{ maxHeight: "200px", overflow: "auto" }}>{item.subtitle}</div> : <></>}
											Veja mais <i className="fas fa-plus plus"></i>
										</Link>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div> : <></>

}
