import React from 'react';
import { ApiLocal, chunkArray } from '../../constants'
import ReactHtmlParser from 'react-html-parser';
import FooterHighlights from '../footer/highlights';
import * as $ from 'jquery';

export default function Project(props) {
  const [project, setProject] = React.useState({ images: [], partner: { avatar: {} } });

  React.useEffect(() => {
    ApiLocal("GET", `partner_projects/${props.match.params.id}`)
      .then(response => { setProject({ ...response.data, images: groupImages(response.data.images) }); })
      .catch(response => { props.history.push("/404"); })
  }, [props.history, props.match.params.id])


  $(function () {
    $('.navGal a:first-child').addClass('active');
    $('.navGal a').click(function (e) {
      e.preventDefault();
      $('.navGal a').removeClass('active');
      $(this).addClass('active');
      var itemShow = $(this).attr('data-sh');
      $('.gal-item-sh .item').hide();
      $('.gal-item-sh #gal-' + itemShow).fadeIn();
    });
  });

  const groupImages = (images) => images && images.length ? chunkArray(images, 6) : []

  return (
    <>
      <div className="top-interna img-wide" style={{ backgroundImage: "url(lib/assets/img/content/top-projetos.jpg)" }}>
        <div className="container position-relative">
          <div className="row h-100 d-flex align-items-center">
            <div className="col-md-3">
              <div className="campanha-logo">
                <img src="lib/assets/img/logo-campanha.png" alt='logo-campanha' />
              </div>
            </div>
            <div className="col-md-9">
              <div className="page-title">
                <h1>Projetos</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container js-container position-relative">

          <h2 className="h2-base-r">
            <span><strong>{project.name}</strong></span>
            <div className="js-r-side"></div>
          </h2>

          <div className="row">
            <div className="col-lg-12">
              {ReactHtmlParser(project.content)}
            </div>
          </div>

          <p>&nbsp;</p>


          <h2 className="h2-base-r d-flex justify-content-between align-items-center">
            <div className="">
              <span>Galeria <strong>de fotos</strong></span>
            </div>
            <div className="navGal">
              {project.images.map((i, e) => <a key={e} href="" data-sh={`${e + 1}`}>{e + 1}</a>)}
            </div>
          </h2>

          <div className="gal-item-sh">
            {project.images.map((images, index) =>
              <div key={index} className="item" id={`gal-${index + 1}`}>
                <div className="row">
                  {images.map((image, index2) => <div key={index2} className="col-lg-6 mb-4"><a data-fancybox={`gallery-${index + 1}`} href={image.attachment.url}><div style={{ backgroundImage: `url(${image.attachment.url}`, height: "270px", backgroundSize: 'auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} /></a></div>)}
                </div>
              </div>
            )}
          </div>


          <p>&nbsp;</p>

          <div className="sessao-autor">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 order-1 order-lg-0 text-center text-lg-right">
                <div className="text">
                  <h3><span className="fnt-pn-light">{project.partner.name}</span></h3>
                  <div className="text">{ReactHtmlParser(project.partner.aboutme)}
                    {project.partner.site ? <><br />Site: <a href={project.partner.site} target="_blank" rel="noopener noreferrer">{project.partner.site}</a></> : ""}
                    {project.partner.instagram ? <><br />Instagram: <a href={`https://www.instagram.com/${project.partner.instagram.replace("@", "")}`} target="_blank" rel="noopener noreferrer">{project.partner.instagram}</a></> : ""}
                    {project.partner.email && project.partner.email.email ? <><br /><a href={`mailto:${project.partner.email.email}?subject=Obrafacil: Quero falar com você&body=Olá, conheci você através do site https://obrafacilmais.com.br. Estou interessado em conhecer mais sobre o seu trabalho.`}> {project.partner.email.email}</a></> : ""}
                    {project.partner.address && project.partner.address.city_name ? <><br />{project.partner.address.city_name} - {project.partner.address.state_name}</> : ""}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-0 order-lg-1">
                <div className="photo">
                  <div style={{ backgroundImage: `url(${project.partner.avatar.url}`, height: "200px", backgroundSize: 'auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {FooterHighlights()}
    </>
  )
}
