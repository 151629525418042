import React from "react";
import Moment from 'react-moment';
import { ApiLocal } from "../constants";
import { useSelector } from "react-redux";

export default function Commissions() {
  const partner = useSelector(state => state.partner);
  const [commission, setCommission] = React.useState({})
  const [size, setSize] = React.useState(9)
  const [commissions, setCommissions] = React.useState([])

  React.useEffect(() => {
    if (partner && partner.id)
      ApiLocal('GET', `commissions`)
        .then(response => setCommissions(response.data))
  }, [partner])

  const formatMoney = (n) => n ? "R$ " + n.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+,)/g, "$1.") : "0.0"

  const If = (input) => input ? <Moment format="DD/MM/YYYY">{input}</Moment> : ' - '

  const onClickModal = (id) => (e) => {
    e.preventDefault();
    setCommission(commissions.find(item => item.id === id))
  }

  const formatDate = (n) => {
    if (n) {
      let date = new Date(n);
      return <>{date.getDate()}<br />{date.getMonth()}<br />{date.getFullYear()}</>
    }
  }

  return (
    <>
      <span>Total de items: {commissions.length}</span>
      {commissions.length ? <>
        <table className="table table-md-responsive table-hover table-no-border">
          <thead>
            <tr>
              <th nowrap="true">No. Pedido</th>
              <th nowrap="true">Data da Baixa</th>
              <th nowrap="true">Nome do Cliente</th>
              <th nowrap="true">Valor do Pedido</th>
              <th nowrap="true">Disponível em</th>
              <th style={{ width: "1px" }}></th>
            </tr>
          </thead>
          <tbody>
            {commissions.slice(0, size).map((item, index) =>
              <tr key={index} className="clickable-row" data-toggle="modal" data-target="#modal-ver-mensagem" onClick={onClickModal(item.id)}>
                <td>{item.order_id}</td>
                <td>{If(item.order_date)}</td>
                <td>{item.client_name}</td>
                <td>{formatMoney(item.order_price)}</td>
                <td>{If(item.sent_date)}</td>
                <td><div className="lupa"></div></td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="row justify-content-center">
          {size <= commissions.length ? <button className="btn btn-outline-primary" onClick={e => setSize(size + 5)}>ver mais </button> : null}
        </div></>
        : <></>}
      <div className="modal fade modal-tpl-2 md-cinza" id="modal-ver-mensagem" tabIndex="-1" role="dialog" aria-labelledby="TituloModalCentralizado" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl modal-lg modal-md modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="row d-flex">
                <div className="col-md-3 cols"> </div>
                <div className="col-md-9 cols">
                  <div className="cols-r">
                    <div className="icone"><img src="lib/assets/img/modal-ico-2.png" alt="modal-ico-2" /></div>
                    <h5 className="modal-title">Pedido <strong>{commission.order_id}</strong></h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="row d-flex">
                <div className="col-md-3 cols  cols-l">
                  <div className="">
                    <p className="data">
                      {formatDate(commission.order_date)}
                    </p>
                  </div>
                </div>
                <div className="col-md-9 cols">
                  <div className="cols-r">
                    <form className="tpl-form-1">
                      <div className="form-group">
                        <label className="flabal-dif d-block mb-1">No. Pedido</label>
                        <p className="fnt-s-22"><strong>{commission.order_id}</strong></p>
                      </div>
                      <div className="form-group">
                        <label className="flabal-dif d-block mb-1">Data da Baixa</label>
                        <p className="fnt-s-22"><strong>{If(commission.order_date)}</strong></p>
                      </div>
                      <div className="form-group">
                        <label className="flabal-dif d-block mb-1">Nome do Cliente</label>
                        <p className="fnt-s-22"><strong>{commission.client_name}</strong></p>
                      </div>
                      <div className="form-group">
                        <label className="flabal-dif d-block mb-1">Valor do Pedido</label>
                        <p className="fnt-s-22"><strong>{formatMoney(commission.order_price)}</strong></p>
                      </div>
                      <div className="form-group">
                        <label className="flabal-dif d-block mb-1">Valor pago com vale</label>
                        <p className="fnt-s-22"><strong>{formatMoney(commission.return_price)}</strong></p>
                      </div>
                      <div className="form-group">
                        <label className="flabal-dif d-block mb-1">Porcentagem</label>
                        <p className="fnt-s-22"><strong>{commission.percent}%</strong></p>
                      </div>
                      <div className="form-group">
                        <label className="flabal-dif d-block mb-1">Pontos/Produtos</label>
                        <p className="fnt-s-22"><strong>{commission.points}</strong></p>
                      </div>
                      <div className="form-group">
                        <label className="flabal-dif d-block mb-1">Pontos/Dinheiro</label>
                        <p className="fnt-s-22"><strong>{commission.percent_value}</strong></p>
                      </div>
                      <div className="form-group">
                        <label className="flabal-dif d-block mb-1">Disponível em</label>
                        <p className="fnt-s-22"><strong>{If(commission.sent_date)}</strong></p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row d-flex">
                <div className="col-md-3 cols"> </div>
                <div className="col-md-9 cols">
                  <div className="cols-r">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Fechar">X</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
