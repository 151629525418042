import { put, delay } from 'redux-saga/effects'
import { Types as authTypes } from '../ducks/auth'

export function* requestLogin(action) {
  yield delay(5000)

  const user = {
    logedIn: true,
    authToken: '1a23f3s2bn3131q12h321',
    userEmail: 'some@user.com'
  }

  try {
    yield put({ type: authTypes.LOGIN, payload: user })
  } catch (error) {
  }
}

