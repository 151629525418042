import React from 'react';
import { ApiLocal, chunkArray } from '../../constants'
import FooterHighlights from '../footer/highlights';
import * as $ from 'jquery';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import { isEmail } from '../../helpers';
import ReactHtmlParser from 'react-html-parser';

const useStyle = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

export default function Professionals(props) {
  const classes = useStyle()
  const [partners, setPartners] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [params, setParams] = React.useState({ name: "", email: "", subject: "", message: "" });
  const [partnersArray, setPartnersArray] = React.useState([]);
  const [partnersTmp, setPartnersTmp] = React.useState([]);
  const [perPage] = React.useState(18)
  const [page, setPage] = React.useState(1)
  const [total, setTotal] = React.useState(0)

  React.useEffect(() => {
    setPartnersArray(chunkArray(partners, 3))
  }, [partners]);

  React.useEffect(() => {
    getPartners()
  }, [page]);

  const getPartners = () => {
    setLoading(true)
    ApiLocal("GET", `partners?per_page=${perPage}&page=${page}`)
      .then(response => {
        setPartnersTmp([...partnersTmp, ...response.data]);
        setPartners([...partnersTmp, ...response.data]);
        setTotal(response.headers.total);
        setLoading(false)
      })
  }

  const handleChange = (label) => (event) => setParams({ ...params, [label]: event.target.value })

  const sendEmail = (e) => {
    e.preventDefault()
    setLoading(true)
    if (params.name && params.name.length > 3 && isEmail(String(params.email)))
      ApiLocal("POST", `partners/${selectedItem.id}/send_email`, { ...params })
        .then(response => {
          Swal.fire({
            type: 'success',
            title: response.data.success,
            showConfirmButton: false,
            timer: 1500
          }).then(e => {
            setLoading(false)
            window.$('#modal-enviar-mensagem').modal('hide');
          })
        })
    else
      Swal.fire({
        type: 'error',
        title: !params.name || !(params.name.length > 3) ? "Nome inválido" : !isEmail(String(params.email)) ? "Email inválido" : "Parametros inválidos",
        showConfirmButton: false,
        timer: 1500
      }).then(e => { setLoading(false) })
  }

  $(function () {
    $('.item-md-2 .js-click').click(function () {
      $(this).parent().attr('data-item')
      $('.item-md-2').removeClass('active');
      $('.item-md-2 .description').hide();
      $(this).parent().addClass('active');
      $(this).parent().find('.description').fadeIn();
      // ini_sld_projetos(sldId);
    });
  });

  return (<>

    <div className="top-interna img-wide" style={{ backgroundImage: "url(lib/assets/img/content/top-arquitetos.jpg)" }}>
      <div className="container position-relative">
        <div className="row h-100 d-flex align-items-center">
          <div className="col-md-3">
            <div className="campanha-logo">
              <img src="lib/assets/img/logo-campanha.png" alt="logo-campanha" />
            </div>
          </div>
          <div className="col-md-9">
            <div className="page-title">
              <h1>Profissionais em destaque</h1>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="page-content">
      <div className="container js-container position-relative">
        {partnersArray.map((item, index) =>
          <div key={index} className="row">
            {item.map((partner, index) =>
              <div key={index} className="col-md-4">
                <div className={`item-gal item-md-2 item-${index + 1}`} data-item={index}>
                  <div className="js-click">
                    <div
                      className="image"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: '#eeeeee',
                        borderRadius:'10px'
                      }}>
                      <img
                        src={partner.avatar.url}
                        alt={partner.avatar.url?.split('/').pop()}
                        style={{
                          height: "200px",
                          objectFit: "contain",
                          borderRadius:'5px'
                        }} />
                    </div>
                    <div className="title">{partner.name}</div>
                  </div>
                  <div className="description">
                    <div className="area">
                      <div className="name">{partner.name}</div>
                      <div className="text">{ReactHtmlParser(partner.aboutme)}
                        {partner.site ? <><br />Site: <a href={partner.site} target="_blank" rel="noopener noreferrer">{partner.site}</a></> : ""}
                        {partner.instagram ? <><br />Instagram: <a href={`https://www.instagram.com/${partner.instagram.replace("@", "")}`} target="_blank" rel="noopener noreferrer">{partner.instagram}</a></> : ""}
                        {partner.email && partner.email.email ? <><br /><a href={`mailto:${partner.email.email}?subject=Obrafacil: Quero falar com você&body=Olá, conheci você através do site https://obrafacilmais.com.br. Estou interessado em conhecer mais sobre o seu trabalho.`}> {partner.email.email}</a></> : ""}
                        {partner.address && partner.address.city_name ? <><br />{partner.address.city_name} - {partner.address.state_name}</> : ""}
                      </div>
                      <div className="projects">
                        <h3 className="position-relative">
                          <div className="layer-ltr" style={{ height: "50px" }}>
                            <a href={void (index)} style={{ position: "absolute", right: 0 }} data-toggle="modal" data-target="#modal-enviar-mensagem" onClick={e => { e.preventDefault(); setSelectedItem(partner) }}>ENVIE <br /> UMA MENSAGEM</a>
                          </div>
                          <span className="d-inline-block d-xl-none badge badge-warning align-top"><a href={void (index)} data-toggle="modal" data-target="#modal-enviar-mensagem" onClick={e => { e.preventDefault(); setSelectedItem(partner) }}>ENVIE UMA MENSAGEM</a></span>
                          {partner.projects && partner.projects.length ? <strong>Projetos</strong> : <></>}
                        </h3>
                        {partner.projects && partner.projects.length ?
                          <div className="js-r-side">
                            <div className="prev"></div>
                            <div className="overflow">
                              <div className="area-sld">
                                <div className="row sld-project-int-arq" id={`sld-project-int-arq-${index}`}>
                                  {partner.projects.map((project, index2) => <div key={index2} className="item">
                                    <div className="image-sld"><img src={project.highlight_image} alt={project.highlight_image?.split('/').pop()} width="300" /></div>
                                    <div className="title-sld">{project.name}</div>
                                  </div>)}
                                </div>
                              </div>
                            </div>
                          </div> : <></>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {total > 0 && total > page * 9 ?
          <div className="row justify-content-center m-b-20 m-t-20">
            <button className="btn" disabled={loading} style={{ bottom: "0px", left: "50%", background: "#f6be18", alignItems: "center" }} onClick={e => setPage(page + 1)}>Carregar mais{loading && <CircularProgress size={24} className={classes.buttonProgress} />} </button>
          </div> : <></>}
      </div>
    </div>

    <div className="modal fade modal-tpl-2 md-cinza" id="modal-enviar-mensagem" tabIndex="-1" role="dialog" aria-labelledby="TituloModalCentralizado" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-xl modal-lg modal-md modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="row d-flex">
              <div className="col-md-3 cols"> </div>
              <div className="col-md-9 cols">
                <div className="cols-r">
                  <div className="icone"><img src="lib/assets/img/modal-ico-2.png" alt='modal-ico-2' /></div>
                  <h5 className="modal-title">Mensagem para <strong>{selectedItem.name}</strong></h5>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="row d-flex">
              <div className="col-md-3 cols">
                <div className="cols-l">
                  <p className="data">{new Date().getDate()}<br />{new Date().getMonth()}<br />{new Date().getFullYear()}</p>
                </div>
              </div>
              <div className="col-md-9 cols">
                <div className="cols-r">
                  <form id="form-submit" className="tpl-form-1" onSubmit={sendEmail} >

                    <div className="form-group input-arrow w-100">
                      <label className="form-label" id="label-nome" htmlFor="field-nome">nome</label>
                      <input type="text" name="nome" id="field-nome" className="form-input" autoComplete="off" value={params.name} onChange={handleChange('name')} />
                    </div>

                    <div className="form-group input-arrow w-100">
                      <label className="form-label" id="label-email" htmlFor="field-email">email</label>
                      <input type="text" name="email" id="field-cpemailf" className="form-input" autoComplete="off" value={params.email} onChange={handleChange('email')} />
                    </div>

                    <div className="form-group input-arrow w-100 mb-4">
                      <label className="form-label" id="label-assunto" htmlFor="field-assunto">assunto</label>
                      <input type="text" name="assunto" id="field-assunto" className="form-input" autoComplete="off" value={params.subject} onChange={handleChange('subject')} />
                    </div>

                    <div className="form-group">
                      <label className="labal-dif d-block" id="label-sobre" htmlFor="field-sobre">mensagem</label>
                      <textarea name="sobre" id="field-sobre" rows="5" className="form-control cpt-dif" value={params.message} onChange={handleChange('message')} ></textarea>
                    </div>

                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                    <div className="text-right">
                      <button type="submit" className="btn-bt-send" >{loading ? <CircularProgress /> : "ENVIAR"}</button>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="row d-flex">
              <div className="col-md-3 cols"> </div>
              <div className="col-md-9 cols">
                <div className="cols-r">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Fechar">x</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {FooterHighlights()}
  </>)
}
