import React from 'react'
import { ApiLocal } from '../constants'
import Swal from 'sweetalert2'
import InputMask from 'react-input-mask'
import { isEmail } from '../helpers'
import FooterHighlights from './footer/highlights'

export default function Contact(props) {
  const [contact, setContact] = React.useState({})
  const [disabled, setDisabled] = React.useState(false)

  const sendEmail = (e) => {
    e.preventDefault()
    setDisabled(true)
    let error = validate();
    if (!error)
      ApiLocal('POST', 'contact', contact)
        .then(response => Swal.fire('200!', response.data.success, 'success').then(() => props.history.push("/")))
        .catch(response => Swal.fire({ type: 'error', title: 'Oops...', text: "Houve um erro" }).then(i => setDisabled(false)))
    else
      Swal.fire({ type: 'info', text: error }).then(i => setDisabled(false))
  }

  const handleChange = (key) => (event) => setContact({ ...contact, [key]: event.target.value })

  const validate = () => {
    if (!contact.name || contact.name.length < 3)
      return "Nome invalido";
    else if (!contact.phone && !contact.email)
      return "Telefone ou E-mail não podem fica em branco";
    else if (contact.phone && contact.phone.length !== 15)
      return "Telefone Invalido";
    else if (contact.email && !isEmail(String(contact.email).toLowerCase()))
      return "Email invalido";
    if (!contact.subject || contact.subject.length < 5)
      return "Assunto invalido";
    if (!contact.message || contact.message.length < 5)
      return "Mensagem invalida";
    else
      return;
  }

  return (
    <>
      <div className="top-interna img-wide" style={{ backgroundImage: "url(lib/assets/img/content/top-cadastro.jpg)" }}>
        <div className="container position-relative">
          <div className="row h-100 d-flex align-items-center">
            <div className="col-md-3">
              <div className="campanha-logo">
                <img src="lib/assets/img/logo-campanha.png" alt='logo-campanha' />
              </div>
            </div>
            <div className="col-md-9">
              <div className="page-title">
                <h1>Entre em <strong>contato</strong></h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container js-container position-relative">
          <form className="tpl-form-1 form-cadastro" disabled={disabled} onSubmit={sendEmail}>
            <h2 className="mb-4">Dúvidas<strong> Fale com nossa equipe!</strong></h2>
            <div className="form-group">
              <label className="form-label" htmlFor="field-contact-nome-i">Nome</label>
              <input name="nome" id="field-contact-nome-i" className="form-input" type="text" autoComplete="off" value={contact.name} onChange={handleChange("name")} required />
            </div>

            <div className="form-group input-arrow w-100">
              <label className="form-label" id="label-email" htmlFor="field-contact-email">E-mail</label>
              <input name="email" id="field-contact-email" className="form-input" type="text" autoComplete="off" value={contact.email} onChange={handleChange("email")} />
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="field-contact-subject">Assunto</label>
                  <input name="subject" id="field-contact-subject" className="form-input" type="text" autoComplete="off" value={contact.subject} onChange={handleChange("subject")} required />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group input-arrow w-100">
                  <label className="form-label" id="label-telefone" htmlFor="field-contact-telefone">ddd + telefone</label>
                  <InputMask
                    mask="(99) 99999-9999"
                    maskChar=""
                    name="telefone"
                    id="field-contact-telefone"
                    className="form-input"
                    value={contact.phone}
                    onChange={handleChange("phone")} />
                </div>
              </div>
            </div>

            <div className="form-group mt-3">
              <label className="labal-dif d-block" id="label-mensagem" htmlFor="field-contact-mensagem">Sua mensagem</label>
              <textarea name="mensagem" id="field-contact-mensagem" rows="8" className="form-control cpt-dif" value={contact.message} onChange={handleChange("message")} required></textarea>
            </div>

            <p>&nbsp;</p>

            <div className="row">
              <div className="col-lg-7 d-flex justify-content-end align-items-center"></div>
              <div className="col-lg-5 position-relative">
                <button type="submit" className="btn btn-block bt-cadastro" disabled={disabled}>ENVIAR MENSAGEM</button>
                <div className="js-r-side"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {FooterHighlights()}
    </>
  )
}
