import { takeLatest, all } from 'redux-saga/effects'
import { Types as authTypes } from '../ducks/auth'

import { requestLogin } from './auth'

export default function* root() {
  yield all([
    takeLatest(authTypes.REQ_LOGIN, requestLogin)
  ])
}
