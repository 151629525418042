
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  stores_handle_change: [],
})

const INITIAL_STATE = []

const handle_change = (state = INITIAL_STATE, action) => {
  return [
    ...state,
    ...action.stores
  ]
}


export default createReducer(INITIAL_STATE,
  {
    [Types.STORES_HANDLE_CHANGE]: handle_change,
  })