import React from "react";
import FormLogin from "./formLogin";
import { ApiLocal } from "../constants";
import { Link } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import { useDispatch } from "react-redux";
const MONTHS = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

export default function Dashboard(props) {
  const date = new Date();
  const month = date.getMonth() - 1;
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    highlights: [],
    events: [],
    campaign_images: [],
    videos: []
  });

  React.useEffect(() => {
    ApiLocal("GET", `web`).then(response => {
      setState({ ...response.data });
      localStorage.setItem('warning', response.data.warning)
      dispatch({ type: "STORES_HANDLE_CHANGE", stores: response.data.stores })
    });
  }, []);

  return (
    <>
      <div className="top-principal">
        <div className="sld-principal">
          <Hidden smUp>
            <div className="item img-wide" style={{ backgroundImage: "url(https://hubcoapp-images.s3-sa-east-1.amazonaws.com/img/content/sld-principal-1.jpg)" }}></div>
          </Hidden>
          <div className="item img-wide" style={{ backgroundImage: "url(https://hubcoapp-images.s3-sa-east-1.amazonaws.com/img/content/sld-principal-1.jpg)" }}></div>
          <div className="item img-wide" style={{ backgroundImage: "url(https://hubcoapp-images.s3-sa-east-1.amazonaws.com/img/content/sld-principal-2.jpg)" }}></div>
          <div className="item img-wide" style={{ backgroundImage: "url(https://hubcoapp-images.s3-sa-east-1.amazonaws.com/ObraFacilAlpha.jpeg)" }}></div>
          <div className="item img-wide" style={{ backgroundImage: "url(https://hubcoapp-images.s3-sa-east-1.amazonaws.com/img/content/sld-principal-3.jpg)" }}></div>
        </div>
        <div className="layer" style={{ backgroundColor: "rgb(0,0,0,0.3)" }}>
          <div className="container">
            <div className="row h-100">
              <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="d-md-flex">
                  <div className="cols area-form-login-1">
                    <div className="imagem">
                      <img src="lib/assets/img/logo-campanha.png" alt="logo-campanha" />
                    </div>
                    <div className="acesso">
                      {localStorage.getItem("access-token") ? <></> : <FormLogin />}
                    </div>
                  </div>
                  <div className="cols area-text-sld">
                    <div className="text-sld">
                      <div className="sld-text-items">
                        <Hidden smUp>
                          <div className="item area-form-login-1" id="txt-ctrl-0">
                            <div className="sld-title" style={{ fontFamily: 'proxima_novaregular' }}>
                              <strong>Login do parceiro</strong>
                            </div>
                            <div className="sld-description acesso">
                              {localStorage.getItem("access-token") ? <></> : <FormLogin />}
                            </div>
                          </div>
                          <div className="item" id="txt-ctrl-1">
                            <div className="sld-title" style={{ fontFamily: 'proxima_novaregular' }}>
                              Bem vindo ao <br /> <strong>Obra Fácil Mais</strong>
                            </div>
                            <div className="sld-description" style={{ fontFamily: 'proxima_novaregular' }}>
                              O OBRA FÁCIL MAIS é o programa de relacionamento da Loja Obra Fácil direcionado a profissionais de arquitetura, design de interiores e decoração. <br />
                              O programa também possibilita vantagens especiais aos clientes de nossos parceiros, com descontos e formas de pagamento diferenciadas. <br />
                              A Obra Fácil reconhece o papel fundamental dos profissionais parceiros no processo de fidelização de nossos clientes.
                            </div>
                          </div>
                          <div className="item" id="txt-ctrl-2">
                            <div className="sld-title" style={{ fontFamily: 'proxima_novaregular' }}>
                              Bem vindo ao <br /> <strong>Obra Fácil Mais</strong>
                            </div>
                            <div className="sld-description" style={{ fontFamily: 'proxima_novaregular' }}>
                              Obra Fácil Mais é destinado a arquitetos, decoradores e designer de interiores que acumulam pontos dentro da nossa plataforma.
                            </div>
                          </div>
                          <div className="item" id="txt-ctrl-3">
                            <div className="sld-title" style={{ fontFamily: 'proxima_novaregular' }}>
                              Loja<br /> <strong>Alphaville</strong>
                              <strong></strong>
                            </div>
                            <div className="sld-description" style={{ fontFamily: 'proxima_novaregular' }}>
                              Recém inaugurada loja de Alphaville localizada na Rua Araguaia, 410 - (011) 4191-1508 que visa atender a demanda de arquitetos da região com produtos a pronta entrega e com ótimos preços.
                            </div>
                          </div>
                          <div className="item" id="txt-ctrl-4">
                            <div className="sld-title" style={{ fontFamily: 'proxima_novaregular' }}>
                              Bem vindo ao <br /> <strong>Obra Fácil Mais</strong>
                            </div>
                            <div className="sld-description" style={{ fontFamily: 'proxima_novaregular' }}>
                              Nossa plataforma de relacionamento esta interligada com nossos canais de conteúdo quem possuem um grande numero de pageviews (Blog Obra facil, Plataforma de Relacionamento, Site, Instagram, Facebook e outros parceiros ) para que possam ser divulgados os parceiros que mais movimentaram a plataforma.
                            </div>
                          </div>
                        </Hidden>
                        <Hidden smDown>
                          <div className="item" id="txt-ctrl-0">
                            <div className="sld-title" style={{ fontFamily: 'proxima_novaregular' }}>
                              Bem vindo ao <br /> <strong>Obra Fácil Mais</strong>
                            </div>
                            <div className="sld-description" style={{ fontFamily: 'proxima_novaregular' }}>
                              Obra Fácil Mais é o programa de relacionamento da Loja Obra Facil. <br />
                              Ele concede descontos e formas de pagamento diferenciada aos clientes dos arquitetos, decoradores e designer de interiores. <br />
                              Para nós, estes profissionais são fundamentais na relação entre as lojas e seus clientes.
                            </div>
                          </div>
                          <div className="item" id="txt-ctrl-1">
                            <div className="sld-title" style={{ fontFamily: 'proxima_novaregular' }}>
                              Bem vindo ao <br /> <strong>Obra Fácil Mais</strong>
                            </div>
                            <div className="sld-description" style={{ fontFamily: 'proxima_novaregular' }}>
                              Obra Fácil Mais é destinado a arquitetos, decoradores e designer de interiores que acumulam pontos dentro da nossa plataforma.
                            </div>
                          </div>
                          <div className="item" id="txt-ctrl-2">
                            <div className="sld-title" style={{ fontFamily: 'proxima_novaregular' }}>
                              Loja<br /> <strong>Alphaville</strong>
                              <strong></strong>
                            </div>
                            <div className="sld-description" style={{ fontFamily: 'proxima_novaregular' }}>
                              Recém inaugurada loja de Alphaville localizada na Rua Araguaia, 410 - (011) 4191-1508 que visa atender a demanda de arquitetos da região com produtos a pronta entrega e com ótimos preços.
                            </div>
                          </div>
                          <div className="item" id="txt-ctrl-3">
                            <div className="sld-title" style={{ fontFamily: 'proxima_novaregular' }}>
                              Bem vindo ao <br /> <strong>Obra Fácil Mais</strong>
                            </div>
                            <div className="sld-description" style={{ fontFamily: 'proxima_novaregular' }}>
                              Nossa plataforma de relacionamento esta interligada com nossos canais de conteúdo quem possuem um grande numero de pageviews (Blog Obra facil, Plataforma de Relacionamento, Site, Instagram, Facebook e outros parceiros ) para que possam ser divulgados os parceiros que mais movimentaram a plataforma.
                            </div>
                          </div>
                        </Hidden>
                      </div>
                      <div className="sld-pg"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sessao-t-b2 img-wide" style={{ backgroundImage: "url(lib/assets/img/content/back-b2.jpg)" }}>
        <div className="container position-relative">
          <div className="row">
            <div className="col-lg-7 order-1 order-lg-0">
              <div className="s-text">
                <p className="s-info1">Nossa plataforma tem como objetivo estreitar relacionamento com os profissionais parceiros participantes de nosso programa de relacionamento, além de promover interfaces com nossos principais fornecedores: Deca, Tramontina, Mekal, entre outros.</p>
                <p className="s-info1">Ela  também promove campanhas com premiações mensais e anuais, além de gerar pontuações em todas as compras realizadas em nossas lojas ou no site,  que poderão ser trocados por prêmios.</p>
                <p className="s-info1">Os clientes de nossos parceiros tem tratamento diferenciado em preços e formas de pagamento.</p>
                <br />
                <div className="a-botao" >
                  <Link style={{ fontSize: "30px" }} to="/cadastrar">FAÇA SEU CADASTRO</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-5 order-0 order-lg-1">
              <div className="s-title">POR QUE PARTICIPAR?</div>
              <div className="s-info">
                Muitos benefícios e descontos para você e seu cliente.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sessao-projects">
        <div className="container position-relative">
          <div className="row">
            <div className="col-lg-4 cols">
              <div className="area-text">
                <div className="row d-lg-flex">
                  <div className="col-lg-6 x">
                    <div className="s-titulo">
                      Con<br />teú<br />do
                    </div>
                  </div>
                  <div className="col-lg-6 x">
                    <div className="s-text">
                      <p style={{ fontFamily: 'proxima_novaregular' }}>
                        Desenvolvemos diversos formatos para apoio e divulgação dos projetos de nossos parceiros, utilizando os mais variados recursos e mídias sociais.
                      </p>
                      <a style={{ color: "blue" }} href="https://www.youtube.com/channel/UC-YzYZyjTNNWnWzXHecA8mg/featured" rel="noopener noreferrer" target="_blank">Clique aqui</a>
                      <br />
                      <Hidden smDown>
                        <div className="pg" style={{ marginBottom: '-40px' }}>
                          <div className="np prev"></div>
                          <div className="np next"></div>
                        </div>
                      </Hidden>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8 cols" style={{ display: 'flex', alignItems: 'flex-end' }}>
              <div className="area-sld-project">
                <div className="sld-project">
                  <div className="item">
                    {state.videos.length && state.videos[0] ? <>
                      <div className="imagem">
                        <iframe title="videos0" src={state.videos[0].url} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
                      </div>
                      <div className="titulo" style={{ fontFamily: 'proxima_novaregular', whiteSpace: 'nowrap' }}>
                        {state.videos[0].text}
                      </div>
                    </> : ""}
                  </div>
                  <div className="item">
                    {state.videos.length && state.videos[1] ? <>
                      <div className="imagem" >
                        <iframe title="videos1" src={state.videos[1].url} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
                      </div>
                      <div className="titulo" style={{ fontFamily: 'proxima_novaregular' }}>
                        {state.videos[1].text}
                      </div>
                    </> : ""}
                  </div>
                  <div className="item">
                    {state.videos.length && state.videos[2] ? <>
                      <div className="imagem" >
                        <iframe title="videos2" src={state.videos[2].url} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
                      </div>
                      <div className="titulo" style={{ fontFamily: 'proxima_novaregular' }}>
                        {state.videos[2].text}
                      </div>
                    </> : ""}
                  </div>
                  <div className="item">
                    {state.videos.length && state.videos[3] ? <>
                      <div className="imagem" >
                        <iframe title="videos3" src={state.videos[3].url} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
                      </div>
                      <div className="titulo" style={{ fontFamily: 'proxima_novaregular' }}>
                        {state.videos[3].text}
                      </div>
                    </> : ""}
                  </div>
                  <div className="item">
                    {state.videos.length && state.videos[4] ? <>
                      <div className="imagem" >
                        <iframe title="videos4" src={state.videos[4].url} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
                      </div>
                      <div className="titulo" style={{ fontFamily: 'proxima_novaregular' }}>
                        {state.videos[4].text}
                      </div>
                    </> : ""}
                  </div>
                  <div className="item">
                    {state.videos.length && state.videos[5] ? <>
                      <div className="imagem" >
                        <iframe title="videos5" src={state.videos[5].url} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
                      </div>
                      <div className="titulo" style={{ fontFamily: 'proxima_novaregular' }}>
                        {state.videos[5].text}
                      </div>
                    </> : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      <div className="sessao-profile img-wide" style={{ backgroundImage: "url(lib/assets/img/content/back-profile-temp.jpg)" }}>
        <div className="container position-relative">
          <div className="b-arrow"></div>
          <div className="t-arrow"></div>

          <div className="row d-flex align-items-center">
            <div className="col-lg-8 cols">
              <div className="row">
                {state.partners && state.partners.map((item, index) => (
                  <div className="col-md-3 col-6" key={index}>
                    <Link to={`/profissionais/${item.id}`}>
                      <div className="item" style={{ maxWidth: "230px" }}>
                        <div className="foto" style={{
                          display: 'flex',
                          justifyContent: 'center',
                          backgroundColor: '#4E4E4E',
                          borderRadius: '10px'
                        }}>
                          {item.avatar && item.avatar.url && item.avatar.url.length > 1 &&
                            <img
                              width='150px'
                              style={{
                                height: "180px",
                                objectFit: "contain",
                                borderRadius: '5px'
                              }}
                              src={item.avatar.url}
                              alt={item.name} />
                          }
                        </div>
                        <div className="nome" style={{ fontFamily: 'proxima_novaregular' }}>
                          {item.name}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-4 cols">
              {state.partners && state.partners.length ? <>
                <div className="s-titulo">PROFISSIONAIS</div>
                <div className="s-text" style={{ fontFamily: 'proxima_novaregular' }}>
                  parceiros em destaque no mês de {MONTHS[month]}.
                </div>
              </> : <></>}
            </div>
          </div>
        </div>
      </div>

      {
        state.highlights.length ?
          <div className="sessao-t-b3">w
            <div className="container position-relative">
              <div className="row d-flex align-items-center">
                <div className="col-lg-4">
                  <div className="s-titulo-area">
                    <div className="s-titulo">DESTAQUES OBRA FÁCIL MAIS</div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="row x-row">
                    {state.highlights.map((item, index) =>
                      <div className="col-lg-4" key={index}>
                        <Link to={item.kind === "event" ? `/eventos/${item.id}` : "/destaques"}>
                          <div className="item">
                            <div className="titulo" style={{ fontFamily: 'proxima_novaregular' }}>{item.title}</div>
                            <div className="imagem"><img src={item.image.small} alt={item.image.small.split('/').pop()} /></div>
                            <div className="chamada" style={{ fontSize: "13px", color: "black", fontFamily: 'proxima_novaregular' }}>{(() => {
                              let today = new Date();
                              let a = item.starts_in ? new Date(item.starts_in) : null
                              let b = item.expires_at ? new Date(item.expires_at) : null
                              if (a && today.getDay() === a.getDay() && today.getMonth() === a.getMonth() && today.getFullYear() === a.getFullYear())
                                if (today.getHours() >= a.getHours() && today.getMinutes() >= a.getMinutes())
                                  return `Começou as ${a.getHours()}:${a.getMinutes()} ${b ? `e termina as ${b.getHours()}:${b.getMinutes()}` : ''}`;
                                else
                                  return `Começa hoje as ${a.getHours()}:${a.getMinutes()} ${b ? `e termina as ${b.getHours()}:${b.getMinutes()}` : ''}`;
                              return a ? b ? a.toLocaleString() + ' - ' + b.toLocaleString() : a.toLocaleString() : "";
                            })()}</div>
                            {item.subtitle ? <div className="chamada" style={{ maxHeight: "200px", overflow: "auto", fontFamily: 'proxima_novaregular' }}>{item.subtitle.length > 100 ? item.subtitle.substring(0, 99) + "..." : item.subtitle}</div> : <></>}
                            Veja mais <i className="fas fa-plus plus"></i>
                          </div>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> : <></>
      }

    </>
  );
}
