import React from "react";
import { markdown } from 'markdown';
import ReactHtmlParser from 'react-html-parser';

const mdPrivatePolicy = `**Política de privacidade***

A Loja Obra Fácil construiu a aplicação obrafacil track como uma aplicação gratuita.Este SERVIÇO é fornecido pela Loja Obra Fácil sem qualquer custo e destina - se a ser utilizado tal como está.

Esta página é utilizada para informar os visitantes sobre as nossas políticas de recolha, utilização e divulgação de Informações Pessoais, caso alguém decida utilizar o nosso Serviço.

Se optar por utilizar o nosso Serviço, então concorda com a recolha e utilização de informações em relação a esta política. As Informações Pessoais que recolhemos são utilizadas para fornecer e melhorar o Serviço. Não utilizaremos ou partilharemos as suas informações com ninguém, excepto conforme descrito na presente Política de Privacidade.

Os termos utilizados na presente Política de Privacidade têm os mesmos significados que nos nossos Termos e Condições, os quais são acessíveis na faixa da obrafacil, salvo definição em contrário na presente Política de Privacidade.

**Recolha e Utilização de Informação***

Para uma melhor experiência, ao utilizar o nosso Serviço, podemos exigir que nos forneça certas informações pessoalmente identificáveis, incluindo mas não limitadas ao nome, aniversário, localização geográfica, imagens. As informações que solicitarmos serão retidas por nós e utilizadas conforme descrito nesta política de privacidade.

A aplicação utiliza serviços de terceiros que podem recolher informações utilizadas para identificar o utilizador.

Link para a política de privacidade de fornecedores de serviços de terceiros utilizados pela aplicação

- [Google Play Services](https://www.google.com/policies/privacy/)

**Dados de registo***

Queremos informar que sempre que utiliza o nosso Serviço, em caso de erro na aplicação, recolhemos dados e informações(através de produtos de terceiros) no seu telefone chamado Log Data.Estes Dados de Registo podem incluir informações tais como o endereço IP("IP") do seu dispositivo, o nome do dispositivo, a versão do sistema operativo, a configuração da aplicação ao utilizar o nosso Serviço, a hora e data da sua utilização do Serviço, e outras estatísticas.

**Cookies***

Os cookies são ficheiros com uma pequena quantidade de dados que são normalmente utilizados como identificadores anônimos únicos.Estes são enviados para o seu navegador a partir dos websites que visita e são armazenados na memória interna do seu dispositivo.

Este Serviço não utiliza explicitamente estes "cookies". No entanto, a aplicação pode utilizar código de terceiros e bibliotecas que utilizam "cookies" para recolher informações e melhorar os seus serviços. O utilizador tem a opção de aceitar ou recusar estes "cookies" e saber quando um cookie está a ser enviado para o seu dispositivo. Se optar por recusar os nossos cookies, poderá não ser possível utilizar algumas partes deste Serviço.

**Prestadores de Serviços***

Podemos empregar empresas e indivíduos terceiros devido às seguintes razões:

- Para facilitar o nosso serviço;
- Para prestar o Serviço em nosso nome;
- Para executar serviços relacionados com o Serviço; ou
  - Para nos ajudar na análise da forma como o nosso Serviço é utilizado.

Queremos informar os utilizadores deste Serviço que estes terceiros têm acesso às suas Informações Pessoais. A razão é executar as tarefas que lhes são atribuídas em nosso nome. No entanto, são obrigados a não divulgar ou utilizar as informações para qualquer outro fim.

**Segurança***

Valorizamos a sua confiança ao fornecer - nos as suas Informações Pessoais, pelo que nos esforçamos por utilizar meios comercialmente aceitáveis para a sua proteção. Mas lembre - se que nenhum método de transmissão através da Internet, ou método de armazenamento eletrônicos é 100 % seguro e fiável, e não podemos garantir a sua segurança absoluta.

**Links para Outros Sites***

Este Serviço pode conter links para outros sites. Se clicar num link de terceiros, será direcionado para esse site. Note que estes sítios externos não são operados por nós. Por conseguinte, aconselhamo - lo vivamente a rever a Política de Privacidade destes sítios. Não temos qualquer controlo e não assumimos qualquer responsabilidade pelo conteúdo, políticas de privacidade, ou práticas de quaisquer sites ou serviços de terceiros.

**Privacidade das crianças***

Estes Serviços não se dirigem a ninguém com idade inferior a 13 anos.Não recolhemos intencionalmente informações pessoalmente identificáveis de crianças com menos de 13 anos de idade. No caso de descobrirmos que uma criança com menos de 13 anos nos forneceu informações pessoais, apagamos - las imediatamente dos nossos servidores. Se for pai ou tutor e tiver conhecimento de que o seu filho nos forneceu informações pessoais, por favor contacte - nos para que possamos realizar as ações necessárias.

**Alterações a esta Política de Privacidade***

Podemos atualizar a nossa Política de Privacidade de tempos a tempos.Assim, é aconselhável que reveja esta página periodicamente para quaisquer alterações. Iremos notificá - lo de quaisquer alterações, colocando a nova Política de Privacidade nesta página.

Esta política é eficaz a partir de 2022 - 07 - 19

**Contacte - nos***

Se tiver quaisquer perguntas ou sugestões sobre a nossa Política de Privacidade, não hesite em contactar - nos em arthur.moura@lojaobrafacil.com.br.
`

export default function PrivacyPolicy() {

  return (
    <div className="page-content">
      <div className="container js-container position-relative">
        <div id="accordion" className="accordion">
          <div className="card">
            <div className="privacy-policy">
              {ReactHtmlParser(markdown.toHTML(mdPrivatePolicy))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
