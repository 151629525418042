import React from "react"
import { Switch, Route, withRouter } from "react-router-dom"
import App from "./App"
import ResetPassword from './pages/resetPassword';

function Routes() {
  return (
    <Switch>
      <Route exact path="/redefinir-senha" component={ResetPassword} />
      <Route path="/" component={App} />
    </Switch>
  )
}

export default withRouter(Routes)
