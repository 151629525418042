import React from 'react';
import { ApiLocal } from '../constants'
import ReactHtmlParser from 'react-html-parser';
import {
  CardMedia,
  Typography,
  Hidden
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    height: "400px",
    maxWidth: "600px",
    maxHeight: "600px",
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));


export default function Partner(props) {
  const classes = useStyles();
  const [partner, setPartner] = React.useState({
    avatar: {}
  });

  React.useEffect(() => {
    let id = props.match.params.id
    if (id)
      ApiLocal("GET", `partners/${id}`)
        .then(response => setPartner(response.data))
    // props.history.push({ search: '' })
  }, [])

  return (
    <div className="container">
      <div className="row p-t-10">
        <div className="col-md-6 col-sm-12">
          <Typography component="h5" variant="h5">
            {partner.name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {partner.site && <a href={partner.site} target="blank" />}
          </Typography>
          <Hidden smDown implementation="css">
            <Typography className={classes.cardBody} variant="body2" color="textSecondary" component="p">{ReactHtmlParser(partner.aboutme)}</Typography>
          </Hidden>
        </div>
        <div className="col-md-6 col-sm-12">
          {partner.avatar && partner.avatar.url ?
            <CardMedia
              className={classes.cover}
              image={partner.avatar.url}
              title="Live from space album cover"
            />
            :
            <div className="container">
              <div className="row justify-content-center p-t-100 p-b-100">
                <div className="col-md-4">
                  <img src="https://hubcoapp-images.s3-sa-east-1.amazonaws.com/logos/obrafacil-logo.png" alt="loading" className="card-img-top op-0-6" />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
