import React from 'react'
import { ApiLocal } from '../constants'
import Swal from 'sweetalert2'
import InputMask from 'react-input-mask'
import { isEmail } from '../helpers'
import FooterHighlights from './footer/highlights'
import { LinearProgress } from '@material-ui/core'

export default function Indication(props) {
  const [indication, setIndication] = React.useState({})
  const [loading, setLoading] = React.useState(false)

  const signUp = (e) => {
    e.preventDefault()
    setLoading(true)
    let error = validate();
    if (!error)
      if (!localStorage.getItem(indication.email) && !localStorage.getItem(indication.phone)) {
        localStorage.setItem(indication.email, true)
        localStorage.setItem(indication.phone, true)
        ApiLocal('POST', 'indication', indication)
          .then(response => Swal.fire('200!', response.data.success, 'success')
            .then(() => setTimeout(() => {
              props.history.push("/")
            }, 1000)))
          .catch(response => {
            setLoading(false)
            Swal.fire({ type: 'error', title: 'Oops...', text: "Houve um erro" })
            localStorage.removeItem(indication.email)
            localStorage.removeItem(indication.phone)
          })
      }
      else {
        Swal.fire({ type: 'info', timer: 1000, text: "Você já indicou esse parceiro" })
        setLoading(false)
      }
    else {
      Swal.fire({ type: 'info', text: error })
      setLoading(false)
    }
  }

  const handleChange = (key) => (event) => setIndication({ ...indication, [key]: event.target.value })

  const validate = () => {
    if (!indication.client_name || indication.client_name.length < 5)
      return "Seu nome invalido";
    else if (!indication.partner_name || indication.partner_name.length < 5)
      return "Nome do arquiteto invalido";
    else if (!indication.phone && !indication.email)
      return "Telefone ou E-mail não podem fica em branco";
    else if (indication.phone && indication.phone.length !== 15)
      return "Telefone Invalido";
    else if (indication.email && !isEmail(String(indication.email).toLowerCase()))
      return "Email invalido";
    else
      return;
  }

  return (
    <>

      <div className="top-interna img-wide" style={{ backgroundImage: "url(lib/assets/img/content/top-indique-arquiteto.jpg)" }}>
        <div className="container position-relative">
          <div className="row h-100 d-flex align-items-center">
            <div className="col-md-3">
              <div className="campanha-logo">
                <img src="lib/assets/img/logo-campanha.png" alt='logo-campanha' />
              </div>
            </div>
            <div className="col-md-9">
              <div className="page-title">
                <h1>Indique seu <strong>arquiteto</strong></h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container js-container position-relative">
          <form className="tpl-form-1 form-cadastro" loading={loading} onSubmit={signUp}>

            <h2 className="mb-4">Indique seu arquiteto para o <strong>Obra Fácil Mais!</strong></h2>

            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p> */}


            <div className="form-group">
              <label className="form-label" htmlFor="field-indication-nome-i">seu nome</label>
              <input name="nome-i" id="field-indication-nome-i" className="form-input" type="text" autoComplete="off" value={indication.client_name} onChange={handleChange("client_name")} />
            </div>

            <h2 className="mt-5 mb-4">Dados do <strong>arquiteto</strong></h2>

            <div className="form-group">
              <label className="form-label" htmlFor="field-indication-nome">nome do arquiteto</label>
              <input name="nome" id="field-indication-nome" className="form-input" type="text" autoComplete="off" value={indication.partner_name} onChange={handleChange("partner_name")} />
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="field-indication-site">site/instagram</label>
              <input name="site" id="field-indication-site" className="form-input" type="text" autoComplete="off" value={indication.site} onChange={handleChange("site")} />
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group input-arrow w-100">
                  <label className="form-label" id="label-email" htmlFor="field-indication-email">email</label>
                  <input name="email" id="field-indication-email" className="form-input" type="text" autoComplete="off" value={indication.email} onChange={handleChange("email")} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group input-arrow w-100">
                  <label className="form-label" id="label-telefone" htmlFor="field-indication-telefone">ddd + telefone</label>
                  <InputMask
                    mask="(99) 99999-9999"
                    maskChar=""
                    name="telefone"
                    id="field-indication-telefone"
                    className="form-input"
                    value={indication.phone}
                    onChange={handleChange("phone")} />
                </div>
              </div>
            </div>

            <div className="form-group mt-3">
              <label className="labal-dif d-block" id="label-mensagem" htmlFor="field-indication-mensagem">Observações</label>
              <textarea name="mensagem" id="field-indication-mensagem" rows="8" className="form-control cpt-dif" value={indication.description} onChange={handleChange("description")}></textarea>
            </div>

            <p>&nbsp;</p>

            <div className="row">
              <div className="col-lg-7 d-flex justify-content-end align-items-center"></div>
              <div className="col-lg-5 position-relative">
                <button disabled={loading} type="submit" className="btn btn-block bt-cadastro" style={{ cursor: loading ? "wait" : "pointer" }}>
                  INDICAR ARQUITETO
                </button>
                <div className="js-r-side" style={{ display: 'flex', alignItems: 'flex-end' }}>
                  {loading && <LinearProgress style={{ width: "100%" }} />}
                </div>
              </div>
            </div>

          </form>

        </div>
      </div>
      {FooterHighlights()}
    </>
  )
}
