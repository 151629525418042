import React, { useState } from 'react';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core";
import 'moment/locale/pt-br';
import clsx from 'clsx';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { Tooltip } from '@material-ui/core';
import { yellow, red } from '@material-ui/core/colors';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "20px",
        color: "white",
        backgroundColor: red
      }
    }
  }
});
const useStyles = makeStyles({
  textField: {
    backgroundColor: "white",
    color: "black",
  },
  fieldDate: {
    paddingTop: "7px"
  },
  tooltip: {
    backgroundColor: red,
    color: "white"
  },
});

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: yellow.A700,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: yellow.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: yellow["700"],
      },
      current: {
        color: yellow["900"],
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: yellow["400"],
      },
    },
  },
});

export default function MaterialDate(props) {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-BR">
      <MuiThemeProvider theme={materialTheme}>
        <KeyboardDatePicker
          variant="inline"
          format={props.format || "DD/MM/YYYY"}
          disabled={props.disabled}
          readOnly={props.readOnly}
          disableFuture={props.disableFuture}
          disablePast={props.disablePast}
          className={clsx(classes.textField, classes.fieldDate)}
          label={props.label}
          views={["date", "month"]}
          inputVariant="standard"
          value={props.value ? moment(props.value) : null}
          fullWidth
          autoOk
          onChange={props.onChange}
          invalidDateMessage="Data inválida"
          invalidLabel="Inválido"
          maxDateMessage="Data deve ser menor que a data maxima"
          minDateMessage="Data deve ser maior que a data minima"
        />
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

// export default function MaterialDate(props) {
//   const classes = useStyles();
//   return (
//     <ThemeProvider theme={materialTheme}>
//       <DatePicker
//         variant="inline"
//         format={props.format || "DD/MM/YYYY"}
//         disabled={props.disabled}
//         readOnly={props.readOnly}
//         disableFuture={props.disableFuture}
//         disablePast={props.disablePast}
//         className={clsx(classes.textField, classes.fieldDate)}
//         label={props.label}
//         views={["date", "month"]}
//         inputVariant="standard"
//         value={props.value ? moment(props.value) : null}
//         fullWidth
//         autoOk
//         onChange={props.onChange}
//         invalidDateMessage="Data inválida"
//         invalidLabel="Inválido"
//         maxDateMessage="Data deve ser menor que a data maxima"
//         minDateMessage="Data deve ser maior que a data minima"
//       />
//     </ThemeProvider>
//   )
// }

export function MaterialDateTime(props) {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-BR">
      <KeyboardDateTimePicker
        variant="inline"
        format={props.format || "DD/MM/YYYY hh:mm:ss"}
        disabled={props.disabled}
        readOnly={props.readOnly}
        disableFuture={props.disableFuture}
        disablePast={props.disablePast}
        className={clsx(classes.textField, classes.fieldDate)}
        label={props.label}
        inputVariant="outlined"
        value={props.value ? moment(props.value) : null}
        fullWidth
        autoOk
        onChange={props.onChange}
        invalidDateMessage="Data inválida"
        invalidLabel="Inválido"
        maxDateMessage="Data deve ser menor que a data maxima"
        minDateMessage="Data deve ser maior que a data minima"
      />
    </MuiPickersUtilsProvider>
  )
}

export function MaterialBetweenDateTime(props) {
  const classes = useStyles()
  const [focusedInput, setFocusedInput] = useState({})
  const { startDate, endDate, onChange, error } = props
  return (
    <MuiThemeProvider theme={theme}>
      <Tooltip title="selecione uma data" open={error} classes={classes.tooltip}>
        <DateRangePicker
          startDate={startDate}
          startDatePlaceholderText="início"
          endDatePlaceholderText="Final"
          startDateId="MaterialBetweenDateTimeStart"
          endDate={endDate}
          showDefaultInputIcon={true}
          showClearDates={true}
          reopenPickerOnClearDates={false}
          endDateId="MaterialBetweenDateTimeEnd"
          onDatesChange={({ startDate, endDate }) => onChange(startDate, endDate)}
          focusedInput={focusedInput}
          onFocusChange={focusedInput => setFocusedInput(focusedInput)}
          isOutsideRange={() => false}
        />
      </Tooltip>
    </MuiThemeProvider>
  )
}


