import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { ApiLocal } from '../constants';
import Carousel from 'react-material-ui-carousel';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    // backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
}));

export default function Warnings() {
  const classes = useStyles();
  const [warnings, setWarnings] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    ApiLocal("GET", `warnings`)
      .then(response => { resolveWarnings(response.data); console.log("äqui"); })
      .catch(response => { setWarnings([]) });
  }, [])

  const resolveWarnings = (war) => {
    let date = new Date()
    war = war.filter(i => new Date(i.starts_at) <= date && new Date(i.end_at) >= date)
    setWarnings(war)
    setOpen(!!war.length)
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Carousel>
            {warnings && warnings.map((item, index) => <img key={index} src={item.url} alt={item.url.split('/').pop()} />)}
          </Carousel>
        </div>
      </Modal>
    </div >
  );
}
