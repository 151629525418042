import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'
import createRootReducer from './ducks'
import history from '../routes/history'

const sagaMiddleware = createSagaMiddleware()

// const middlewares = [
//   sagaMiddleware,
//   routerMiddleware(history)
// ]
const store = createStore(
      createRootReducer(history), 
      applyMiddleware(routerMiddleware(history), 
      sagaMiddleware
    )
)

sagaMiddleware.run(rootSaga)


export default store;