import React from 'react';

export default function Error404() {
  return (
    <div>
      <div className="">
        <div className="row justify-content-center p-t-100 p-b-100">
          <div className="col-md-4">
            <img src="lib/assets/img/404.png" alt="404" className="card-img-top op-0-6" />
          </div>
        </div>
      </div>
    </div>
  )
}