import React from 'react';
import { ApiLocal } from '../../constants'
import FooterHighlights from '../footer/highlights';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';

export default function Projects(props) {
  const [projects, setProjects] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    ApiLocal("GET", `partner_projects?page=${page}&per_page=9`)
      .then(response => { setProjects([...projects, ...response.data]); setTotal(response.headers.total); setLoading(false) })
      .catch(response => {  setLoading(false) })
  }, [page])

  return (
    <>
      <div className="top-interna img-wide" style={{ backgroundImage: "url(lib/assets/img/content/top-projetos.jpg)" }}>
        <div className="container position-relative">
          <div className="row h-100 d-flex align-items-center">
            <div className="col-md-3">
              <div className="campanha-logo">
                <img src="lib/assets/img/logo-campanha.png" alt='logo-campanha' />
              </div>
            </div>
            <div className="col-md-9">
              <div className="page-title">
                <h1>Projetos</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container js-container position-relative">
          <p>Conheça os projetos de nossos parceiros.</p>
          <div className="row">
            {projects.map(item =>
              <div key={item.id} className="col-lg-4 col-md-6">
                <a href={`projetos/${item.metadata}`}>
                  <div className="item-gal">
                    <div className="image" style={{ backgroundImage: `url(${item.highlight_image}`, height: "350px", backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} ></div>
                    <div className="title">{item.name}<br /> <strong>{item.partner.name}</strong></div>
                  </div>
                </a>
              </div>
            )}
            {!projects.length && loading ? <Box className="m-l-10" pt={0.5}><Skeleton variant="rect" width={300} height={200} /><Skeleton /><Skeleton width="60%" /></Box> : <></>}
            {!projects.length && loading ? <Box className="m-l-10" pt={0.5}><Skeleton variant="rect" width={300} height={200} /><Skeleton /><Skeleton width="60%" /></Box> : <></>}
            {!projects.length && loading ? <Box className="m-l-10" pt={0.5}><Skeleton variant="rect" width={300} height={200} /><Skeleton /><Skeleton width="60%" /></Box> : <></>}
          </div>

          {total > 0 && projects.length > total ?
            <div className="row justify-content-end">
              <button className="btn" style={{ bottom: "0px", left: "50%", background: "#f6be18" }} onClick={e => setPage(page + 1)}>Carregar mais</button>
            </div> : <></>}
        </div>
      </div>
      {FooterHighlights()}
    </>
  )
}
