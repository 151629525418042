import React from 'react'
import { ApiLocal, signOut, HandleToast } from '../constants'
import Commissions from './commissions';
import { useSelector, useDispatch } from 'react-redux';
import FooterHighlights from '../pages/footer/highlights'

export default function Dashboard() {
  const partner = useSelector(state => state.partner);
  const dispatch = useDispatch();

  React.useEffect(() => {
    ApiLocal('GET', 'selfs')
      .then(resp => {
        dispatch({ type: "PARTNER_HANDLE_CHANGE", partner: resp.data })
      })
      .catch(response => {
        HandleToast(response); signOut();
      });
  }, [])

  // const copyCoupon = () => {
  //   if (partner.coupon) {
  //     var textField = document.createElement('textarea')
  //     textField.innerText = partner.coupon.code
  //     document.body.appendChild(textField)
  //     textField.select()
  //     document.execCommand('copy')
  //     textField.remove()
  //   }
  // }

  return (<>
    <div className="top-interna img-wide" style={{ backgroundImage: "url(lib/assets/img/content/top-cadastro.jpg)" }}>
      <div className="container position-relative">
        <div className="row h-100 d-flex align-items-center">
          <div className="col-md-3">
            <div className="campanha-logo">
              <img src="lib/assets/img/logo-campanha.png" alt="logo-campanha" />
            </div>
          </div>
          <div className="col-md-9">
            <div className="page-title">
              <h1 className="menor">{partner.name}, <br /> <span>bem-vindo ao <strong>Obra Fácil Mais</strong></span></h1>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div className="page-content">
      <div className="container position-relative">

        <p>Seu saldo em dinheiro é equivalente a 4% do valor das compras atreladas e devem ser resgatados dentro do mês do recebimento. seu saldo em produtos é o equivalente a um bônus de mais 0,5% do valor das compras atreladas e podem ser acumulados.</p>

        <div className="row justify-content-between">
          <div className="col-md-6">
            <div className="info-panel md-azul">
              <div className="icon"><img src="lib/assets/img/ico-info-panel-1.png" alt="ico-info-panel-1" /></div>
              <div className="title">Saldo em <br /><strong>dinheiro</strong></div>
              <div className="num">{partner.money_pi},<span>00</span></div>
              <div className="complemento">reais</div>
              <a href={partner.premio_ideal_rescue.money} rel="noopener noreferrer" target="_blank" className="link">RESGATAR</a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="info-panel md-amarelo">
              <div className="icon"><img src="lib/assets/img/ico-info-panel-2.png" alt="ico-info-panel-2" /></div>
              <div className="title">Saldo em <br /><strong>produtos</strong></div>
              <div className="num">{partner.points_pi}</div>
              <div className="complemento">produtos</div>
              <a href={partner.premio_ideal_rescue.points} rel="noopener noreferrer" target="_blank" className="link">RESGATAR</a>
            </div>
          </div>
        </div>

      </div>
    </div>



    <div className="container position-relative">
      <span style={{ fontSize: "28px" }}> <strong>Entenda sua pontuação:</strong></span>
      <p style={{ fontSize: "15px" }}>Pontos de pedidos fechados entre os dias 1 a 30 (31) do mês, serão disponibilizados para resgate por volta do dia 15 do próximo mês e devem ser resgatados até o último dia do mês.</p>
      <p style={{ fontSize: "15px" }}>Os pontos na campanha "Resgate em Produtos" podem ser acumulados.</p>
      <p style={{ fontSize: "15px" }}>Serão enviados para a plataforma pontos maiores de 200. Abaixo disso, ficarão no sistema até acumular 200 pontos e então serem enviados para resgate.</p>
    </div>

    <div className="page-content">
      <div className="container position-relative">
        <div className="lista-mensagems">
          <div className="area-table-msg">
            <div className="title">
              <span> <strong>Histórico de pedidos</strong></span>
            </div>
            {partner ? <Commissions partner_id={partner.id} /> : <></>}
          </div>
        </div>
      </div>
    </div>

    {FooterHighlights()}

  </>
  )
}
